import React, { forwardRef, ReactNode } from "react"
import styled from "styled-components"

export interface ButtonAtomProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick: any
  onMouseOut?: () => void
  onMouseOver?: () => void
  type?: "button" | "submit"
}

const ButtonAtom = forwardRef<HTMLButtonElement, ButtonAtomProps>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onMouseOut,
      onMouseOver,
      type = "button",
    },
    ref
  ) => (
    <styles.Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      ref={ref}
      type={type}
    >
      {children}
    </styles.Button>
  )
)

const styles = {
  Button: styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    margin: 0;
    padding: 0;
  `,
}

export default ButtonAtom
