import { useEffect, useRef } from "react"

const SWIPE_THRESHOLD = 40

const { abs } = Math

export enum SwipeDirection {
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
  UP = "up",
}

/**
 * Hook with callback to listen for touch swipe and return direction
 *
 * @param callback
 */
function useSwipe(
  callback: (direction: SwipeDirection) => void,
  isEnabled: boolean = true
) {
  const startXRef = useRef<number>(0)
  const startYRef = useRef<number>(0)

  const onTouchStart = (event: TouchEvent) => {
    if (isEnabled && event.changedTouches && event.changedTouches.length > 0) {
      startXRef.current = event.changedTouches[0].clientX
      startYRef.current = event.changedTouches[0].clientY
    }
  }

  const onTouchEnd = (event: TouchEvent) => {
    if (isEnabled && event.changedTouches && event.changedTouches.length > 0) {
      const endX = event.changedTouches[0].clientX,
        endY = event.changedTouches[0].clientY,
        swipeX = abs(endX - startXRef.current),
        swipeY = abs(endY - startYRef.current)

      if (swipeX > swipeY && swipeX > SWIPE_THRESHOLD) {
        callback(
          endX > startXRef.current ? SwipeDirection.RIGHT : SwipeDirection.LEFT
        )
      } else if (swipeY > swipeX && swipeY > SWIPE_THRESHOLD) {
        callback(
          endY > startYRef.current ? SwipeDirection.UP : SwipeDirection.DOWN
        )
      }
    }
  }

  useEffect(() => {
    if ("ontouchstart" in window) {
      window.addEventListener("touchstart", onTouchStart)
      window.addEventListener("touchend", onTouchEnd)
    }

    return (): void => {
      if ("ontouchstart" in window) {
        window.removeEventListener("touchstart", onTouchStart)
        window.removeEventListener("touchend", onTouchEnd)
      }
    }
  }, [])
}

export default useSwipe
