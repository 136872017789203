import React, { FC, ReactNode } from "react"
import styled from "styled-components"

export interface ContainerAtomProps {
  children?: ReactNode
  className?: string
}

const ContainerAtom: FC<ContainerAtomProps> = ({ children, className }) => (
  <styles.Container className={className}>{children}</styles.Container>
)

const styles = {
  Container: styled.div`
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,
}

export default ContainerAtom
