import React, { FC } from "react"
import styled from "styled-components"

import {
  AnimatedHeadingMolecule,
  AnimatedRichTextMolecule,
} from "@common/components/molecules"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import SectionMolecule, {
  SectionHorizontalAlignments,
  SectionSizes,
  SectionVerticalAlignments,
} from "@components/molecules/SectionMolecule"

import { Colors } from "@constants"

import { HomeSection } from "@types/HomeSection"

export interface AgendaItem {
  heading: string
  bodyText: string
}

export interface HomeLocationOrganismProps {
  bodyText: string
  heading: string
}

const HomeLocationOrganism: FC<
  HomeLocationOrganismProps & HomeSection & AnimatedStateComponent
> = ({ animate, bodyText, direction, heading }) => (
  <SectionMolecule
    animate={animate}
    desktopAlignHorizontal={SectionHorizontalAlignments.RIGHT}
    desktopAlignVertical={SectionVerticalAlignments.CENTER}
    mobileAlignVertical={SectionVerticalAlignments.TOP}
    size={SectionSizes.HALF_WIDTH}
  >
    <styles.Container>
      <styles.Heading
        animate={animate}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
        direction={direction}
        size="h3"
      >
        {heading}
      </styles.Heading>
      <styles.BodyText
        animate={animate}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
        direction={direction}
        text={bodyText}
      />
    </styles.Container>
  </SectionMolecule>
)

const styles = {
  Container: styled.div`
    position: relative;

    ${MediaQueries.DESKTOP} {
      right: 8vw;
    }

    ${MediaQueries.XL_DESKTOP} {
      right: 14vw;
    }
  `,
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: ${rem(12)};

    ${MediaQueries.TABLET} {
      margin-bottom: ${rem(20)};
    }

    ${MediaQueries.DESKTOP} {
      margin-bottom: ${rem(30)};
    }

    ${MediaQueries.XL_DESKTOP} {
      margin-bottom: ${rem(40)};
    }
  `,
  BodyText: styled(AnimatedRichTextMolecule)`
    color: ${Colors.WHITE};
    margin-bottom: 10vh;

    > p {
      margin: 0 0 ${rem(24)};
    }

    ${MediaQueries.TABLET} {
      margin-bottom: 0;
    }
  `,
}

export default HomeLocationOrganism
