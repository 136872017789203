import React, { FC } from "react"
import styled from "styled-components"

import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { FormSelectFieldMolecule } from "@components/molecules"
import { getDelay } from "@components/molecules/RSVPFieldset1Molecule"

import {
  RSVPAccommodationOptions,
  RSVPTransportationOptions,
  RSVPDurationOptions,
} from "@utils/schemas/RSVPSchema"

export interface RSVPFieldset2MoleculeProps {
  accommodationLabel: string
  accommodationOptionCampervan: string
  accommodationOptionFamily: string
  accommodationOptionHotel: string
  accommodationOptionNotStaying: string
  accommodationOptionTent: string
  className?: string
  isStaying: boolean
  keyClick: number
  durationLabel: string
  durationOptionThursdayToSunday: string
  durationOptionThursdayToMonday: string
  durationOptionThursdayAndLonger: string
  durationOptionFridayToSunday: string
  durationOptionFridayToMonday: string
  durationOptionFridayAndLonger: string
  transportationLabel: string
  transportationOptionCar: string
  transportationOptionTrain: string
}

const RSVPFieldset2Molecule: FC<
  RSVPFieldset2MoleculeProps & AnimatedStateComponent
> = ({
  accommodationLabel,
  accommodationOptionCampervan,
  accommodationOptionFamily,
  accommodationOptionHotel,
  accommodationOptionNotStaying,
  accommodationOptionTent,
  animate,
  className,
  delay = 0,
  isStaying,
  keyClick = 0,
  durationLabel,
  durationOptionThursdayToSunday,
  durationOptionThursdayToMonday,
  durationOptionThursdayAndLonger,
  durationOptionFridayToSunday,
  durationOptionFridayToMonday,
  durationOptionFridayAndLonger,
  transportationLabel,
  transportationOptionCar,
  transportationOptionTrain,
}) => (
  <styles.Fieldset
    $isEnabled={animate === AnimateStates.ANIMATE_IN}
    className={className}
  >
    <FormSelectFieldMolecule
      animate={animate}
      delay={getDelay(animate, delay, 0.05)}
      items={[
        { value: RSVPAccommodationOptions.EMPTY, label: "" },
        {
          value: RSVPAccommodationOptions.TENT,
          label: accommodationOptionTent,
        },
        {
          value: RSVPAccommodationOptions.CAMPER_VAN,
          label: accommodationOptionCampervan,
        },
        {
          value: RSVPAccommodationOptions.HOTEL,
          label: accommodationOptionHotel,
        },
        {
          value: RSVPAccommodationOptions.FAMILY,
          label: accommodationOptionFamily,
        },
        {
          value: RSVPAccommodationOptions.NOT_STAYING,
          label: accommodationOptionNotStaying,
        },
      ]}
      keyClick={keyClick}
      label={accommodationLabel}
      name="accommodation"
    />
    <FormSelectFieldMolecule
      animate={animate}
      delay={getDelay(animate, delay, 0.1)}
      items={[
        { value: RSVPTransportationOptions.EMPTY, label: "" },
        {
          value: RSVPTransportationOptions.CAR,
          label: transportationOptionCar,
        },
        {
          value: RSVPTransportationOptions.TRAIN,
          label: transportationOptionTrain,
        },
      ]}
      keyClick={keyClick}
      label={transportationLabel}
      name="transportation"
    />
    <FormSelectFieldMolecule
      animate={
        isStaying === false && animate === AnimateStates.ANIMATE_IN
          ? AnimateStates.ANIMATE_OUT
          : animate
      }
      delay={getDelay(animate, delay, 0.15)}
      items={[
        { value: RSVPDurationOptions.EMPTY, label: "" },
        {
          value: RSVPDurationOptions.THURSDAY_TO_SUNDAY,
          label: durationOptionThursdayToSunday,
        },
        {
          value: RSVPDurationOptions.THURSDAY_TO_MONDAY,
          label: durationOptionThursdayToMonday,
        },
        {
          value: RSVPDurationOptions.THURSDAY_AND_LONGER,
          label: durationOptionThursdayAndLonger,
        },
        {
          value: RSVPDurationOptions.FRIDAY_TO_SUNDAY,
          label: durationOptionFridayToSunday,
        },
        {
          value: RSVPDurationOptions.FRIDAY_TO_MONDAY,
          label: durationOptionFridayToMonday,
        },
        {
          value: RSVPDurationOptions.FRIDAY_AND_LONGER,
          label: durationOptionFridayAndLonger,
        },
      ]}
      keyClick={keyClick}
      label={durationLabel}
      name="duration"
    />
  </styles.Fieldset>
)

const styles = {
  Fieldset: styled.fieldset<{ $isEnabled: boolean }>`
    border: 0;
    flex: 100%;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};

    ${MediaQueries.TABLET} {
      max-width: 45%;
    }

    ${MediaQueries.DESKTOP} {
      max-width: 40%;
    }

    ${MediaQueries.XL_DESKTOP} {
      max-width: ${rem(430)};
    }
  `,
}

export default RSVPFieldset2Molecule
