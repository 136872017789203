import { useContext } from "react"

import ApplicationContext, {
  ApplicationContextProps,
} from "../contexts/ApplicationContext"

const useApplication = () =>
  useContext<ApplicationContextProps>(ApplicationContext)

export default useApplication
