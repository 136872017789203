import { rem } from "@common/utils"

enum Spacings {
  BORDER_X_MOBILE = rem(12),
  BORDER_X_DESKTOP = rem(16),

  BORDER_Y_MOBILE = rem(6),
  BORDER_Y_DESKTOP = rem(8),

  SECTION_Y_MOBILE = rem(32),
  SECTION_Y_DESKTOP = rem(40),

  SECTION_LABEL_TOP_MOBILE = rem(44),
  SECTION_LABEL_TOP_DESKTOP = rem(48),

  SECTION_LABEL_TOPx2_MOBILE = rem(88),
  SECTION_LABEL_TOPx2_DESKTOP= rem(96),
}

export default Spacings
