import React, { FC } from "react"

import AnimatedSplitTextAtom, {
  AnimatedSplitTextAtomProps,
} from "@common/components/atoms/AnimatedSplitTextAtom"
import HeadingAtom, {
  HeadingAtomProps,
} from "@common/components/atoms/HeadingAtom"

const AnimatedHeadingMolecule: FC<
  HeadingAtomProps & AnimatedSplitTextAtomProps
> = ({
  animate,
  children,
  className,
  delay = 0,
  direction = 1,
  duration = 0.4,
  is,
  size,
  step = 0.03,
}) => (
  <HeadingAtom className={className} is={is} size={size}>
    <AnimatedSplitTextAtom
      animate={animate}
      delay={delay}
      direction={direction}
      duration={duration}
      step={step}
    >
      {children}
    </AnimatedSplitTextAtom>
  </HeadingAtom>
)

export default AnimatedHeadingMolecule
