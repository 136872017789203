import React, { FC, ReactNode, useMemo } from "react"

import AnimatedSplitTextAtom, {
  AnimatedSplitTextAtomProps,
} from "@common/components/atoms/AnimatedSplitTextAtom"
import RichTextAtom, {
  RichTextAtomProps,
} from "@common/components/atoms/RichTextAtom"

const P = ({ children }: { children: ReactNode }) => <p>{children}</p>

const AnimatedRichTextMolecule: FC<
  RichTextAtomProps & AnimatedSplitTextAtomProps
> = ({
  animate,
  className,
  delay = 0,
  direction = 1,
  duration = 0.4,
  onAnimationComplete,
  step = 0.003,
  text,
}) => {
  const items = useMemo(() => {
    if (typeof document === "object") {
      const div = document.createElement("div")
      div.innerHTML = text
      return div.children.length === 0 ? [text] : [...div.children]
    }
    return [text]
  }, [text])

  return (
    <RichTextAtom className={className}>
      {items?.map((element: HTMLElement | string, index: number) => (
        <P key={index}>
          <AnimatedSplitTextAtom
            animate={animate}
            delay={
              delay +
              index *
                step *
                ((element as HTMLElement)?.innerText?.length ??
                  (element as string).length)
            }
            duration={duration}
            direction={direction}
            onAnimationComplete={
              index === items?.length - 1 ? onAnimationComplete : null
            }
            step={step}
          >
            {(element as HTMLElement)?.innerText ?? element}
          </AnimatedSplitTextAtom>
        </P>
      )) ?? null}
    </RichTextAtom>
  )
}

export default AnimatedRichTextMolecule
