import React, { FC, useMemo, useRef } from "react"
import styled from "styled-components"
import { Variants, motion } from "framer-motion"

import { ListAtom } from "@common/components/atoms"
import { AnimatedRichTextMolecule } from "@common/components/molecules"
import { AnimateStates, MediaQueries } from "@common/constants"
import usePrevious from "@common/hooks/usePrevious"
import { rem } from "@common/utils"

import {
  CloseButtonMolecule,
  MenuBorderMolecule,
  MenuLinkMolecule,
} from "@components/molecules"
import { MenuLinkVariants } from "@components/molecules/MenuLinkMolecule"

import { Colors, Easings } from "@constants"

import { SectionItem } from "@types/HomeSection"

import { HomeSectionNames } from "../templates/Home"

export enum MenuStates {
  INITIAL = "initial",
  ANIMATE_IN = "animateIn",
  ANIMATE_OUT = "animateOut",
}

export interface MenuOrganismProps {
  animate: MenuStates
  dictionary: { [key: string]: string }
  enableRSVP: boolean
  onCloseButtonClick: () => void
  sectionItems: SectionItem[]
}

const backgroundVariants = {
  [MenuStates.INITIAL]: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  [MenuStates.ANIMATE_IN]: {
    opacity: 1,
    transition: {
      duration: 0.66,
      ease: Easings.EaseInOutStrong,
    },
  },
  [MenuStates.ANIMATE_OUT]: {
    opacity: 0,
    transition: {
      delay: 0.33,
      duration: 0.66,
      ease: Easings.EaseInOutSoft,
    },
  },
} as Variants

const DELAY_ANIMATE_IN = 0.3,
  DELAY_ANIMATE_OUT = 0,
  STEP_ANIMATE_IN = 0.04,
  STEP_ANIMATE_OUT = 0.02

const MenuOrganism: FC<MenuOrganismProps> = ({
  animate,
  dictionary,
  enableRSVP,
  onCloseButtonClick,
  sectionItems,
}) => {
  const previousAnimate = usePrevious(animate)
  const key = useRef<number>(0)

  if (
    previousAnimate === AnimateStates.ANIMATE_OUT &&
    animate === AnimateStates.ANIMATE_IN
  ) {
    key.current++
  }

  const numSections = useMemo(() => sectionItems?.length ?? 0, [sectionItems]),
    delay =
      animate === AnimateStates.ANIMATE_IN
        ? DELAY_ANIMATE_IN
        : DELAY_ANIMATE_OUT,
    step =
      animate === AnimateStates.ANIMATE_IN ? STEP_ANIMATE_IN : STEP_ANIMATE_OUT

  return (
    <styles.Container
      $isEnabled={animate === MenuStates.ANIMATE_IN}
      animate={{
        display: animate === MenuStates.ANIMATE_IN ? "flex" : "none",
      }}
      transition={{
        delay: animate === MenuStates.ANIMATE_OUT ? 1 : 0,
      }}
    >
      <styles.Background
        key={`background-${key.current}`}
        initial={{ opacity: 0 }}
        animate={animate}
        variants={backgroundVariants}
      />
      <styles.Border
        animate={animate}
        key={`border-${key.current}`}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.3 : 0}
      />
      <styles.CloseButton
        animate={animate}
        color={Colors.BLACK}
        delay={delay + (animate === MenuStates.ANIMATE_IN ? 0.3 : 0)}
        disabled={animate !== MenuStates.ANIMATE_IN}
        key={`close-${key.current}`}
        onClick={onCloseButtonClick}
        text={dictionary?.closeButton}
      />
      <styles.Navigation>
        <styles.NavigationList>
          <styles.NavigationItem>
            <styles.NavList>
              {sectionItems.map(({ id, label }: SectionItem, index: number) => (
                <styles.NavItem
                  $variant={
                    id === HomeSectionNames.HOME
                      ? MenuLinkVariants.LARGE
                      : MenuLinkVariants.NORMAL
                  }
                  key={id}
                >
                  <MenuLinkMolecule
                    animate={animate}
                    delay={delay + index * step}
                    href={`#${id}`}
                    onClick={(event: MouseEvent) => {
                      event.preventDefault()
                      onCloseButtonClick()
                      window.location.hash = id
                      return false
                    }}
                    variant={
                      id === HomeSectionNames.HOME
                        ? MenuLinkVariants.LARGE
                        : MenuLinkVariants.NORMAL
                    }
                  >
                    {label}
                  </MenuLinkMolecule>
                </styles.NavItem>
              ))}
            </styles.NavList>
          </styles.NavigationItem>
          <styles.NavigationItem>
            <styles.BlogList>
              <styles.BlogItem>
                <MenuLinkMolecule
                  animate={animate}
                  disabled
                  delay={delay + (numSections + 1) * step}
                  variant={MenuLinkVariants.LARGE}
                >
                  {dictionary?.blog}
                </MenuLinkMolecule>
                {dictionary?.comingSoon && (
                  <styles.ComingSoonText
                    animate={animate}
                    delay={delay + (numSections + 2) * step}
                    text={dictionary?.comingSoon}
                  />
                )}
              </styles.BlogItem>
            </styles.BlogList>
          </styles.NavigationItem>
        </styles.NavigationList>
      </styles.Navigation>
      <styles.Footer>
        {/* <styles.FooterItem>
          <styles.FooterText
            animate={animate}
            delay={delay + (numSections + 1) * step}
            text={dictionary.footerLeft}
          />
        </styles.FooterItem>
        <styles.FooterItem>
          <styles.FooterText
            animate={animate}
            delay={delay + (numSections + 0) * step}
            text={dictionary.footerRight}
          />
        </styles.FooterItem> */}
      </styles.Footer>
    </styles.Container>
  )
}

const styles = {
  Container: styled(motion.div)<{ $isEnabled: boolean }>`
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
  `,
  Background: styled(motion.div)`
    background-color: ${Colors.WHITE};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity;
    z-index: 3;
  `,
  Border: styled(MenuBorderMolecule)`
    pointer-events: none;
    z-index: 4;
  `,
  CloseButton: styled(CloseButtonMolecule)`
    left: ${rem(26)};
    margin: 0;
    position: absolute;
    top: ${rem(16)};
    z-index: 5;

    ${MediaQueries.TABLET} {
      left: ${rem(46)};
      top: ${rem(26)};
    }
  `,
  Navigation: styled.div`
    bottom: ${rem(100)};
    display: flex;
    left: ${rem(54)};
    position: absolute;
    right: ${rem(54)};
    top: ${rem(60)};
    z-index: 6;

    ${MediaQueries.TABLET} {
      bottom: ${rem(54)};
    }
  `,
  NavigationList: styled(ListAtom)`
    display: flex;
    flex-direction: column;
    margin: auto 0;

    ${MediaQueries.DESKTOP} {
      flex-direction: row;
      width: 100%;
    }
  `,
  NavigationItem: styled.li`
    margin: 0;
    padding: 0;

    &:nth-child(2) {
      margin-top: ${rem(20)};
    }

    ${MediaQueries.TABLET} {
      &:nth-child(2) {
        margin-top: ${rem(30)};
      }
    }

    ${MediaQueries.DESKTOP} {
      display: flex;
      flex-grow: 0.5;
      flex-shrink: 0.5;
      margin: 0 0 auto;
      padding-left: ${rem(84)};

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  `,
  NavList: styled(ListAtom)``,
  NavItem: styled.li<{ $variant: MenuLinkVariants }>`
    margin: 0;
    padding: ${rem(6)} 0 ${rem(6)}
      ${({ $variant }) => ($variant === MenuLinkVariants.LARGE ? 0 : rem(18))};

    &:last-child {
      padding-bottom: 0;
    }

    ${MediaQueries.TABLET} {
      padding: ${rem(12)} 0 ${rem(12)}
        ${({ $variant }) => ($variant === MenuLinkVariants.LARGE ? 0 : rem(18))};
    }

    ${MediaQueries.DESKTOP} {
      padding: 0 0 ${rem(40)}
        ${({ $variant }) => ($variant === MenuLinkVariants.LARGE ? 0 : rem(30))};
    }
  `,
  BlogList: styled(ListAtom)``,
  BlogItem: styled.li``,
  ComingSoonText: styled(AnimatedRichTextMolecule)`
    color: ${Colors.BLACK};
    font-size: ${rem(24)};
    letter-spacing: ${rem(-0.3)};
    line-height: ${rem(24)};
    margin: ${rem(12)} 0 0 ${rem(18)};
    opacity: 0.3;

    ${MediaQueries.DESKTOP} {
      font-size: ${rem(40)};
      letter-spacing: ${rem(-0.93)};
      line-height: ${rem(40)};
      margin: ${rem(40)} 0 0 ${rem(18)};
    }
  `,
  Footer: styled(ListAtom)`
    bottom: ${rem(32)};
    display: flex;
    flex-direction: column;
    left: ${rem(34)};
    position: absolute;
    right: ${rem(34)};
    z-index: 3;

    ${MediaQueries.TABLET} {
      align-items: flex-end;
      bottom: ${rem(40)};
      flex-direction: row;
      left: ${rem(44)};
      right: ${rem(44)};
    }
  `,
  FooterItem: styled.li`
    ${MediaQueries.TABLET} {
      display: flex;
      flex-grow: 0.5;
      flex-shrink: 0.5;

      &:nth-child(2) {
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
      }
    }
  `,
  FooterText: styled(AnimatedRichTextMolecule)`
    color: ${Colors.BLACK};
    font-size: ${rem(14)};
    letter-spacing: ${rem(-0.7)};
    line-height: ${rem(14)};
    margin-bottom: 0;

    > p {
      margin: 0;
    }

    ${MediaQueries.DESKTOP} {
      font-size: ${rem(20)};
      letter-spacing: ${rem(-1)};
      line-height: ${rem(20)};
    }
  `,
}

export default MenuOrganism
