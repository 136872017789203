import { useContext } from "react"

import BackgroundContext, {
  BackgroundContextProps,
} from "@contexts/BackgroundContext"

const useBackground: BackgroundContextProps = () =>
  useContext<BackgroundContextProps>(BackgroundContext)

export default useBackground
