import React, { FC } from "react"
import styled from "styled-components"

import { AnimatedSplitTextAtom, LinkAtom } from "@common/components/atoms"
import { LinkAtomProps } from "@common/components/atoms/LinkAtom"
import { MediaQueries } from "@common/constants"
import { rem } from "@common/utils"

import { AnimatedComponent } from "@common/types"
import { Colors, Typography } from "@constants"

export enum MenuLinkVariants {
  NORMAL = "normal",
  LARGE = "large",
}

export interface MenuLinkMoleculeProps {
  onClick?: () => void
  variant?: MenuLinkVariants
}

const MenuLinkMolecule: FC<
  MenuLinkMoleculeProps & LinkAtomProps & AnimatedComponent
> = ({
  animate,
  children,
  delay,
  href,
  onClick,
  variant = MenuLinkVariants.NORMAL,
}) => (
  <styles.Link $variant={variant} href={href} onClick={onClick}>
    <AnimatedSplitTextAtom animate={animate} delay={delay}>
      {children}
    </AnimatedSplitTextAtom>
  </styles.Link>
)

interface LinkStyles {
  fontSize: string
  letterSpacing: string
  lineHeight: string
}

const linkStylesMobile: { [key: string]: LinkStyles } = {
  [MenuLinkVariants.NORMAL]: {
    fontSize: rem(24),
    letterSpacing: rem(0.3),
    lineHeight: rem(24),
  },
  [MenuLinkVariants.LARGE]: {
    fontSize: rem(30),
    letterSpacing: rem(0.38),
    lineHeight: rem(30),
  },
}

const linkStylesTablet: { [key: string]: LinkStyles } = {
  [MenuLinkVariants.NORMAL]: {
    fontSize: rem(32),
    letterSpacing: rem(-0.75),
    lineHeight: rem(32),
  },
  [MenuLinkVariants.LARGE]: {
    fontSize: rem(50),
    letterSpacing: rem(-1.17),
    lineHeight: rem(50),
  },
}

const linkStylesDesktop: { [key: string]: LinkStyles } = {
  [MenuLinkVariants.NORMAL]: {
    fontSize: rem(40),
    letterSpacing: rem(-0.93),
    lineHeight: rem(40),
  },
  [MenuLinkVariants.LARGE]: {
    fontSize: rem(70),
    letterSpacing: rem(-1.63),
    lineHeight: rem(70),
  },
}

const styles = {
  Link: styled(LinkAtom)<{ $variant: MenuLinkVariants }>`
    color: ${Colors.BLACK};
    font-family: "${Typography.FONT_FAMILY_HEADING1}", Arial;
    font-size: ${({ $variant }) =>
      linkStylesMobile[$variant ?? MenuLinkVariants.NORMAL]?.fontSize};
    letter-spacing ${({ $variant }) =>
      linkStylesMobile[$variant ?? MenuLinkVariants.NORMAL]?.letterSpacing};
    line-height: ${({ $variant }) =>
      linkStylesMobile[$variant ?? MenuLinkVariants.NORMAL]?.lineHeight};

    ${MediaQueries.TABLET} {
      font-size: ${({ $variant }) =>
        linkStylesTablet[$variant ?? MenuLinkVariants.NORMAL]?.fontSize};
    letter-spacing ${({ $variant }) =>
      linkStylesTablet[$variant ?? MenuLinkVariants.NORMAL]?.letterSpacing};
    line-height: ${({ $variant }) =>
      linkStylesTablet[$variant ?? MenuLinkVariants.NORMAL]?.lineHeight};
    }

    ${MediaQueries.DESKTOP} {
      font-size: ${({ $variant }) =>
        linkStylesDesktop[$variant ?? MenuLinkVariants.NORMAL]?.fontSize};
    letter-spacing ${({ $variant }) =>
      linkStylesDesktop[$variant ?? MenuLinkVariants.NORMAL]?.letterSpacing};
    line-height: ${({ $variant }) =>
      linkStylesDesktop[$variant ?? MenuLinkVariants.NORMAL]?.lineHeight};
    }    
  `,
}

export default MenuLinkMolecule
