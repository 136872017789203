import React, { FC } from "react"
import styled from "styled-components"

import {
  AnimatedHeadingMolecule,
  AnimatedRichTextMolecule,
} from "@common/components/molecules"
import { MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { Colors } from "@constants"

export interface SliderMoleculeProps {
  bodyText: string
  className?: string
  delay?: number
  heading: string
}

const FAQSlideMolecule: FC<SliderMoleculeProps & AnimatedStateComponent> = ({
  animate,
  answer,
  className,
  delay = 0,
  question,
}) => (
  <styles.Slide className={className}>
    <styles.QuestionText animate={animate} delay={delay} size="h5">
      {question}
    </styles.QuestionText>
    <styles.AnswerText animate={animate} delay={delay} text={answer} />
  </styles.Slide>
)

const styles = {
  Slide: styled.div`
    display: block;
    position: relative;
    width: 100%;

    ${MediaQueries.DESKTOP} {
      max-width: 50%;
      padding-right: ${rem(30)};
    }
  `,
  QuestionText: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    margin: 0 0 ${rem(12)};
  `,
  AnswerText: styled(AnimatedRichTextMolecule)`
    color: ${Colors.WHITE};
    margin-bottom: 0;

    > p {
      margin: 0 0 ${rem(20)};
    }
  `,
}

export default FAQSlideMolecule
