import React, { FC, FormEvent, useRef } from "react"
import { Variants, motion } from "framer-motion"
import { useField } from "formik"
import styled from "styled-components"

import {
  AnimatedSplitTextAtom,
  FormFieldAtom,
  LabelAtom,
  SelectAtom,
} from "@common/components/atoms"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedComponent } from "@common/types"
import { rem } from "@common/utils"

import { ErrorIconAtom } from "@components/atoms"
import { BorderMolecule } from "@components/molecules"

import { Colors, Easings, Typography } from "@constants"

import useShake from "@hooks/useShake"

import { ERROR_MESSAGE } from "@utils/schemas/RSVPSchema"

export interface FormSelectOptionItem {
  label: string
  value: string
}

export interface FormSelectFieldMoleculeProps {
  items: FormSelectOptionItem[]
  keyClick: number
  label: string
  name: string
}

const variants = {
  [AnimateStates.INITIAL]: {
    opacity: 0,
  },
  [AnimateStates.ANIMATE_IN]: ({ delay }: { delay: number }) => ({
    opacity: 1,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay }: { delay: number }) => ({
    opacity: 0,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
} as Variants

const FormSelectFieldMolecule: FC<
  FormSelectFieldMoleculeProps & AnimatedComponent
> = ({ animate, delay, items, keyClick, label, name }) => {
  const ref = useRef<FormFieldAtom>(null)

  const [field, meta, helpers] = useField({ name })
  const { value } = field
  const { error } = meta
  const { setError, setValue } = helpers

  let labelAnimate = animate,
    labelDelay = delay,
    labelDuration = 0.4

  if (animate === AnimateStates.ANIMATE_IN) {
    labelAnimate =
      value !== "" && value !== "-" ? AnimateStates.ANIMATE_OUT : animate
    labelDuration = value !== "" && value !== "-" ? 0 : 0.4
    labelDelay = value !== "" || value === "-" ? 0 : delay
  }

  const hasError = error === ERROR_MESSAGE,
    color = hasError ? Colors.ERROR : Colors.BLACK

  useShake(ref, keyClick, hasError)

  return (
    <>
      <styles.Field $isEnabled={animate === AnimateStates.ANIMATE_IN} ref={ref}>
        <styles.Border
          animate={animate}
          borderColor={color}
          delay={delay}
          initial={AnimateStates.INITIAL}
          ref={ref}
        />
        <styles.Label
          animate={{ color }}
          transition={{
            duration: 0.3,
            delay: 0,
          }}
        >
          <AnimatedSplitTextAtom
            initial={AnimateStates.INITIAL}
            animate={labelAnimate}
            delay={labelDelay}
            direction={1}
            duration={labelDuration}
            step={0.03}
          >
            {label}
          </AnimatedSplitTextAtom>
        </styles.Label>
        <styles.Icon
          animate={{
            opacity: animate === AnimateStates.ANIMATE_IN && !hasError ? 1 : 0,
          }}
          initial={{ opacity: 0 }}
          height="10"
          transition={{ delay: hasError ? 0 : labelDelay }}
          viewBox="0 0 16 10"
          width="16"
        >
          <path
            d="M14.3492086,1.2407434 C14.7685345,0.881321247 15.3998345,0.929882784 15.7592566,1.34920863 C16.0910309,1.73627864 16.0751741,2.30396566 15.7409603,2.67170703 L15.6507914,2.7592566 L8,9.31707778 L0.349208627,2.7592566 C-0.0701172162,2.39983445 -0.118678753,1.76853447 0.240743398,1.34920863 C0.572517691,0.962138618 1.1359512,0.890986124 1.55048125,1.1650252 L1.65079137,1.2407434 L8,6.682 L14.3492086,1.2407434 Z"
            fill={Colors.BLACK}
          />
        </styles.Icon>
        <styles.Select
          animate={animate}
          custom={{ delay }}
          initial={{ opacity: 0 }}
          onChange={(event: FormEvent<HTMLSelectElement>) => {
            setValue(event.currentTarget.value)
            setError(
              event.currentTarget.value === "-" ? ERROR_MESSAGE : undefined
            )
          }}
          value={value}
          variants={variants}
        >
          {items.map((item: FormSelectOptionItem) => (
            <option key={`${item.value}-${item.label}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </styles.Select>
        <styles.ErrorIcon
          $hasError={hasError && animate === AnimateStates.ANIMATE_IN}
          color={Colors.ERROR}
        />
      </styles.Field>
    </>
  )
}

const styles = {
  Field: styled(FormFieldAtom)<{ $isEnabled: boolean }>`
    margin-bottom: ${rem(16)};
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
    position: relative;
    width: 100%;

    ${MediaQueries.DESKTOP} {
      margin-bottom: ${rem(20)};
    }
  `,
  Border: BorderMolecule,
  Label: styled(motion(LabelAtom))`
    left: ${rem(12)};
    pointer-events: none;
    position: absolute;
    top: ${rem(14)};

    ${MediaQueries.DESKTOP} {
      left: ${rem(22)};
      top: ${rem(19)};
    }
  `,
  Icon: styled(motion.svg)`
    pointer-events: none;
    position: absolute;
    right: ${rem(16)};
    top: calc(50% - ${rem(5)});
  `,
  Select: styled(motion(SelectAtom))`
    appearance: none;
    color: ${Colors.BLACK};
    cursor: pointer;
    display: flex;
    padding: ${rem(14)} ${rem(12)};
    width: inherit;

    outline: none;

    font-family: ${Typography.FONT_FAMILY_BODY};
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_LABEL)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_LABEL)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_LABEL)};

    &::-ms-expand {
      display: none;
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_LABEL)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_LABEL)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_LABEL)};
    }

    ${MediaQueries.DESKTOP} {
      padding: ${rem(20)} ${rem(22)} ${rem(17)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_LABEL)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_LABEL)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_LABEL)};
    }
  `,
  ErrorIcon: styled(ErrorIconAtom)<{ $hasError: boolean }>`
    opacity: ${({ $hasError }) => ($hasError ? 1 : 0)};
    height: ${rem(24)};
    position: absolute;
    right: ${rem(12)};
    pointer-events: none;
    top: ${rem(12)};
    transition: opacity ${Easings.CssEaseInOutStrong} 300ms;
    width: ${rem(24)};

    ${MediaQueries.DESKTOP} {
      top: ${rem(16)};
    }
  `,
}

export default FormSelectFieldMolecule
