import React, { FC } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import {
  AnimatedSplitTextAtom,
  ButtonAtom,
  TextAtom,
} from "@common/components/atoms"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils/UnitUtils"

import { Colors, Easings } from "@constants"

import { BackgroundColors } from "@types/Background"

export interface HeaderButtonMoleculeProps {
  className?: string
  color?: BackgroundColors
  disabled?: boolean
  onClick: () => void
  text: string
}

const HeaderButtonMolecule: FC<
  HeaderButtonMoleculeProps & AnimatedStateComponent
> = ({
  animate,
  className,
  color = BackgroundColors.WHITE,
  delay,
  disabled = false,
  onClick,
  text,
}) => (
  <styles.Button
    className={className}
    disabled={disabled || animate !== AnimateStates.ANIMATE_IN}
    onClick={onClick}
  >
    <styles.Label
      initial={{ color: Colors.WHITE }}
      animate={{
        color,
      }}
      transition={{
        duration: 0.4,
        ease: Easings.EaseInOutStrong,
      }}
    >
      <AnimatedSplitTextAtom animate={animate} delay={delay}>
        {text}
      </AnimatedSplitTextAtom>
    </styles.Label>
  </styles.Button>
)

const styles = {
  Button: styled(ButtonAtom)`
    border: 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: ${rem(60)};
    margin: auto ${rem(8)};
    pointer-events: auto;
    padding: 0 ${rem(8)};
    width: auto;

    &[disabled] {
      pointer-events: none;
    }

    ${MediaQueries.TABLET} {
      width: auto;
    }
  `,
  Label: styled(motion(TextAtom))`
    margin: auto;
    position: relative;
    top: ${rem(5)};
  `,
}

export default HeaderButtonMolecule
