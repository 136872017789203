import React, { FC } from "react"
import styled from "styled-components"

import { SpringScrollMolecule } from "@common/components/molecules"

import { Orientations } from "@common/constants"

interface LayoutMoleculeProps {
  children: any
  orientation: Orientations
  scrollEnabled: boolean
}

const LayoutMolecule: FC<LayoutMoleculeProps> = ({
  children,
  orientation = Orientations.VERTICAL,
  scrollEnabled,
}) => (
  <styles.Container>
    <SpringScrollMolecule enabled={scrollEnabled} orientation={orientation}>
      {children}
    </SpringScrollMolecule>
  </styles.Container>
)

const styles = {
  Container: styled.div`
    display: block;
    height: 100%;
    width: 100%;
  `,
  Wrapper: styled.div`
    display: block;
    min-height: 100%;
  `,
}

export default LayoutMolecule
