import React, { FC, ReactNode, forwardRef } from "react"
import styled from "styled-components"

import { MediaQueries } from "@common/constants"

import { rem } from "@common/utils"

import { Typography } from "@constants"

export interface LabelAtomProps {
  children: string | ReactNode
  className?: string
}

const LabelAtom = forwardRef<HTMLLabelElement, LabelAtomProps>(
  ({ children, className }, ref) => (
    <styles.Label className={className} ref={ref}>
      {children}
    </styles.Label>
  )
)

const styles = {
  Label: styled.label`
    font-family: ${Typography.FONT_FAMILY_BODY};
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_LABEL)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_LABEL)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_LABEL)};

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_LABEL)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_LABEL)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_LABEL)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_LABEL)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_LABEL)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_LABEL)};
    }
  `,
}

export default LabelAtom
