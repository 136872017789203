import React, { forwardRef, ReactNode, RefObject, useMemo } from "react"
import styled from "styled-components"

import { MediaQueries } from "@common/constants"
import { rem } from "@common/utils"

import { Typography } from "@constants"

export interface HeadingAtomProps {
  children: string | ReactNode
  color?: string
  is?: "h1" | "h2" | "h3" | "h4" | "h5" 
  className?: string
  size: "h1" | "h2" | "h3" | "h4" | "h5" | "intro"
}

const HeadingAtom = forwardRef<HTMLElement, HeadingAtomProps>(
  ({ children, className, color, is, size }, ref) => {
    const Tag = useMemo(() => {
      const lookup = {
        h1: styles.Heading1,
        h2: styles.Heading2,
        h3: styles.Heading3,
        h4: styles.Heading4,
        h5: styles.Heading5,
        intro: styles.Intro,
      }
      return lookup[size] ?? styles.Heading1
    }, [size])

    return typeof children === "string" ? (
      <Tag
        as={is ?? size}
        className={className}
        dangerouslySetInnerHTML={{ __html: children }}
        ref={ref as RefObject<HTMLHeadingElement>}
        style={{ color }}
      />
    ) : (
      <Tag
        as={is ?? size}
        className={className}
        ref={ref as RefObject<HTMLHeadingElement>}
        style={{ color }}
      >
        {children}
      </Tag>
    )
  }
)

const styles = {
  Heading1: styled.h1`
    font-family: "${Typography.FONT_FAMILY_HEADING1}", Arial;
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_HEADING1)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_HEADING1)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_HEADING1)};
    margin: 0;
    -webkit-backface-visibility: hidden;

    p {
      margin: 0;
    }

    > span {
      margin-bottom: ${rem(-12)};
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_HEADING1)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_HEADING1)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_HEADING1)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_HEADING1)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_HEADING1)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_HEADING1)};
    }
  `,
  Heading2: styled.h2`
    font-family: "${Typography.FONT_FAMILY_HEADING2}", Arial;
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_HEADING2)};
    font-weight: ${Typography.FONT_WEIGHT_MEDIUM};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_HEADING2)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_HEADING2)};
    margin: 0;
    -webkit-backface-visibility: hidden;

    p {
      margin: 0;
    }

    > span {
      margin-bottom: ${rem(-8)};
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_HEADING2)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_HEADING2)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_HEADING2)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_HEADING2)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_HEADING2)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_HEADING2)};
    }
  `,
  Heading3: styled.h3`
    font-family: "${Typography.FONT_FAMILY_HEADING3}", Arial;
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_HEADING3)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_HEADING3)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_HEADING3)};
    margin: 0;
    -webkit-backface-visibility: hidden;

    p {
      margin: 0;
    }

    > span {
      margin-bottom: ${rem(-8)};
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_HEADING3)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_HEADING3)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_HEADING3)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_HEADING3)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_HEADING3)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_HEADING3)};
    }
  `,
  Heading4: styled.h4`
    font-family: "${Typography.FONT_FAMILY_HEADING4}", Arial;
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_HEADING4)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_HEADING4)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_HEADING4)};
    margin: 0;
    -webkit-backface-visibility: hidden;

    p {
      margin: 0;
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_HEADING4)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_HEADING4)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_HEADING4)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_HEADING4)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_HEADING4)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_HEADING4)};
    }
  `,
  Heading5: styled.h5`
    font-family: "${Typography.FONT_FAMILY_HEADING5}", Arial;
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_HEADING5)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_HEADING5)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_HEADING5)};
    margin: 0;
    -webkit-backface-visibility: hidden;

    p {
      margin: 0;
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_HEADING5)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_HEADING5)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_HEADING5)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_HEADING5)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_HEADING5)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_HEADING5)};
    }
  `,
  Intro: styled.h3`
    font-family: ${Typography.FONT_FAMILY_BODY};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_INTRO)};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_INTRO)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_INTRO)};
    margin: 0;
    -webkit-backface-visibility: hidden;

    > span {
      margin-bottom: ${rem(-8)};
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_INTRO)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_INTRO)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_INTRO)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_INTRO)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_INTRO)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_INTRO)};
    }
  `,
}

export default HeadingAtom
