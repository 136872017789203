import React, { FC } from "react"
import styled from "styled-components"

import SlideIndicatorLinkMolecule, {
  SlideIndicatorItem,
} from "@components/molecules/SlideIndicatorLinkMolecule"

import { Colors } from "@constants"

export interface SlideIndicatorMoleculeProps {
  activeSlide: number
  color?: Colors
  className?: string
  items: SlideIndicatorItem[]
  onUpdate: (value: number) => void
}

const SlideIndicatorMolecule: FC<SlideIndicatorMoleculeProps> = ({
  activeSlide,
  className,
  color,
  items,
  onUpdate,
}) => (
  <styles.List className={className}>
    {items?.map((item: SlideIndicatorItem, index: number) => (
      <styles.Item key={`${item.id}-${index}`}>
        <SlideIndicatorLinkMolecule
          color={color}
          delay={index * 0.05}
          id={item.id}
          isActive={activeSlide === index}
          label={item.label}
          onClick={() => onUpdate(index)}
        />
      </styles.Item>
    ))}
  </styles.List>
)

const styles = {
  List: styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
  `,
  Item: styled.li`
    margin: 0;
    padding: 0;
  `,
}

export default SlideIndicatorMolecule
