import React, { FC } from "react"
import styled from "styled-components"

import {
  AnimatedHeadingMolecule,
  AnimatedRichTextMolecule,
} from "@common/components/molecules"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { Colors, Typography } from "@constants"

export interface SliderMoleculeProps {
  bodyText: string
  className?: string
  delay?: number
  direction?: number
  heading: string
}

const AgendaSlideMolecule: FC<SliderMoleculeProps & AnimatedStateComponent> = ({
  animate,
  bodyText,
  className,
  delay = 0,
  direction = 1,
  heading,
}) => (
  <styles.Slide className={className}>
    {heading && (
      <styles.DayText
        animate={animate}
        delay={delay}
        direction={direction}
        is="h4"
        size="intro"
      >
        {heading}
      </styles.DayText>
    )}
    {bodyText && (
      <styles.BodyText
        animate={animate}
        delay={delay}
        direction={direction}
        text={bodyText}
      />
    )}
  </styles.Slide>
)

const styles = {
  Slide: styled.div`
    display: block;
    position: relative;
    width: 100%;
  `,
  DayText: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    display: block;
    font-family: ${Typography.FONT_FAMILY_BODY_ITALIC};
    margin: 0;
    padding: 0 0 ${rem(12)};
  `,
  BodyText: styled(AnimatedRichTextMolecule)`
    color: ${Colors.WHITE};
    margin: 0 0 auto;

    > p {
      margin: 0 0 ${rem(24)};
    }
  `,
}

export default AgendaSlideMolecule
