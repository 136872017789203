import { RefObject, useEffect } from "react"

import usePrevious from "@common/hooks/usePrevious"

const useShake = (
  element: RefObject<HTMLElement>,
  key: number,
  shake: boolean
) => {
  const previous = usePrevious(key)

  useEffect(() => {
    if (
      shake &&
      element.current &&
      previous !== undefined &&
      key !== previous
    ) {
      element.current.classList.remove("shake")
      window.requestAnimationFrame(() => {
        if (element.current) {
          element.current.classList.add("shake")
        }
      })
    }
  }, [shake, key])
}

export default useShake
