import { animate as FramerAnimate } from "framer-motion"
import * as THREE from "three"

import { Easings } from "@constants"

import vertexShader from "./particle.vert"
import fragmentShader from "./particle.frag"

const glslify = require("glslify")

export interface WebGLParticles {
  animate: (texture: THREE.Texture, duration: number) => void
  init: () => THREE.Object3D
  render: (time: number) => void
  resize: (fovHeight: number) => void
}

const Particles = (): WebGLParticles => {
  let _particleContainer: THREE.Object3D = new THREE.Object3D(),
    _particleGeometry: THREE.InstancedBufferGeometry,
    _particleMaterial: THREE.RawShaderMaterial,
    _particleMesh: THREE.Mesh,
    _particleTexture: THREE.Texture,
    _height: number,
    _meshScale: number,
    _numPoints: number,
    _width: number

  // === Private methods
  const _initPoints = () => {
    _numPoints = _width * _height

    let numVisible = _numPoints,
      threshold = 0,
      originalColors

    // discard pixels darker than threshold #22
    numVisible = 0
    threshold = 34

    const img = _particleTexture.image
    const canvas = document.createElement("canvas")
    const canvasContext = canvas.getContext("2d")

    if (canvasContext === null) return

    canvas.width = _width
    canvas.height = _height
    canvasContext.scale(1, -1)
    canvasContext.drawImage(img, 0, 0, _width, _height * -1)

    const imageData = canvasContext.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    )
    originalColors = Float32Array.from(imageData.data)

    for (let i = 0; i < _numPoints; i++) {
      if (originalColors[i * 4 + 0] > threshold) numVisible++
    }

    const uniforms = {
      uTime: { value: 0 },
      uRandom: { value: 1.0 },
      uDepth: { value: 2.0 },
      uSize: { value: 0.0 },
      uTextureSize: { value: new THREE.Vector2(_width, _height) },
      uTexture: { value: _particleTexture },
      uTouch: { value: null },
    }

    _particleMaterial = new THREE.RawShaderMaterial({
      uniforms,
      vertexShader: glslify(vertexShader),
      fragmentShader: glslify(fragmentShader),
      depthTest: false,
      transparent: true,
    })

    _particleGeometry = new THREE.InstancedBufferGeometry()

    // positions
    const positions = new THREE.BufferAttribute(new Float32Array(4 * 3), 3)
    positions.setXYZ(0, -0.5, 0.5, 0.0)
    positions.setXYZ(1, 0.5, 0.5, 0.0)
    positions.setXYZ(2, -0.5, -0.5, 0.0)
    positions.setXYZ(3, 0.5, -0.5, 0.0)
    _particleGeometry.setAttribute("position", positions)

    // uvs
    const uvs = new THREE.BufferAttribute(new Float32Array(4 * 2), 2)
    uvs.setXYZ(0, 0.0, 0.0)
    uvs.setXYZ(1, 1.0, 0.0)
    uvs.setXYZ(2, 0.0, 1.0)
    uvs.setXYZ(3, 1.0, 1.0)
    _particleGeometry.setAttribute("uv", uvs)

    // index
    _particleGeometry.setIndex(
      new THREE.BufferAttribute(new Uint16Array([0, 2, 1, 2, 3, 1]), 1)
    )

    const indices = new Uint16Array(numVisible)
    const offsets = new Float32Array(numVisible * 3)
    const angles = new Float32Array(numVisible)

    let counter = 0

    for (let i = 0, j = 0; i < _numPoints; i++) {
      if (originalColors[i * 4 + 0] <= threshold) continue

      // Filter counter
      if (counter++ % 20 !== 0) continue

      offsets[j * 3 + 0] = i % _width
      offsets[j * 3 + 1] = Math.floor(i / _width)

      indices[j] = i

      angles[j] = Math.random() * Math.PI

      j++
    }

    _particleGeometry.setAttribute(
      "pindex",
      new THREE.InstancedBufferAttribute(indices, 1, false)
    )
    _particleGeometry.setAttribute(
      "offset",
      new THREE.InstancedBufferAttribute(offsets, 3, false)
    )
    _particleGeometry.setAttribute(
      "angle",
      new THREE.InstancedBufferAttribute(angles, 1, false)
    )

    _particleMesh = new THREE.Mesh(_particleGeometry, _particleMaterial)
    _particleContainer.add(_particleMesh)
  }

  const _initHitArea = () => {
    //
  }

  const _initTouch = () => {
    //
  }

  const _show = (duration: number = 1) => {
    _particleTexture.minFilter = THREE.LinearFilter
    _particleTexture.magFilter = THREE.LinearFilter
    _particleTexture.format = THREE.RGBAFormat

    _width = _particleTexture.image.width
    _height = _particleTexture.image.height

    _initPoints()
    _initHitArea()
    _initTouch()
    _resizeMesh()

    const uniforms = _particleMesh.material.uniforms

    FramerAnimate(0.5, 4.5, {
      duration,
      ease: Easings.EaseInOutSoft,
      onUpdate: value => (uniforms.uSize.value = value),
    })
    FramerAnimate(1.0, 2.0, {
      duration,
      ease: Easings.EaseInOutSoft,
      onUpdate: value => (uniforms.uRandom.value = value),
    })
    FramerAnimate(40.0, 4.0, {
      duration,
      ease: Easings.EaseInOutSoft,
      onUpdate: value => (uniforms.uDepth.value = value),
    })
  }

  const _hide = (duration: number = 0.8) => {
    return new Promise((resolve, reject) => {
      const uniforms = _particleMesh.material.uniforms

      FramerAnimate(uniforms.uRandom.value, 5.0, {
        duration,
        ease: Easings.EaseInOutStrong,
        onUpdate: value => (uniforms.uRandom.value = value),
        onComplete: () => {
          window.requestAnimationFrame(() => {
            _destroy()
            resolve(1)
          })
        },
      })
      FramerAnimate(uniforms.uDepth.value, -10.0, {
        duration,
        ease: Easings.EaseInOutStrong,
        onUpdate: value => (uniforms.uDepth.value = value),
      })
      FramerAnimate(uniforms.uSize.value, 0.0, {
        duration,
        ease: Easings.EaseInOutStrong,
        onUpdate: value => (uniforms.uSize.value = value),
      })
    })
  }

  const _destroy = () => {
    if (!_particleMesh) return

    _particleMesh.parent.remove(_particleMesh)
    _particleMesh.geometry.dispose()
    _particleMesh.material.dispose()
    _particleMesh = null

    // TODO: Add hitArea
  }

  const _resizeMesh = () => {
    if (_meshScale) {
      _particleMesh.scale.set(_meshScale, _meshScale, 1)
    }
  }

  // === Public methods
  // Animate texture from 0 to 1
  const animate = (texture: THREE.Texture, duration: number = 1.5) => {
    if (_particleTexture) {
      _hide(duration * 0.5).then(() => {
        _particleTexture = texture
        _show(duration * 0.5)
      })
    } else {
      _particleTexture = texture

      _show(1)
    }
  }

  const hide = () => {
    if (_particleMesh) {
      _hide().then(() => {
        _particleTexture = null
      })
    }
  }

  // Initialize plane by creating mesh to add to scene
  const init = (): THREE.Object3D => _particleContainer

  // Update based upon time
  const render = (time: number) => {
    if (!_particleMesh) return

    _particleMesh.material.uniforms.uTime.value += time
  }

  // Resize plane
  const resize = (fovHeight: number) => {
    if (!_particleMesh) return

    _meshScale = fovHeight / _height
    _resizeMesh()
  }

  return {
    animate,
    init,
    hide,
    render,
    resize,
  }
}

export default Particles
