import { createGlobalStyle } from "styled-components"

import { rem } from "@common/utils"

const GlobalStylesAtom = createGlobalStyle`
  html,
  body {
    height: 100%;
    margin: 0;
    pointer-events: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #___gatsby {
    display: block;
    height: 100%;
  }

  #gatsby-focus-wrapper {
    height: inherit;    
  }

  .gatsby-image-wrapper img {
    transition: none !important;
    opacity: 1 !important;
  }

  @keyframes SHAKE {
    0% { transform: translateX(0); }
    25% { transform: translateX(${rem(-10)}); }
    50% { transform: translateX(${rem(10)}); }
    75% { transform: translateX(${rem(-10)}); }
    100% { transform: translateX(0); }
  }

  .shake {
    animation: SHAKE 300ms linear 2;    
  }  
`

export default GlobalStylesAtom
