export const BackgroundFragmentShader = `
    uniform float progress;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform vec4 resolution;
    varying vec2 vUv;
    mat2 rotate(float a) {
        float s = sin(a);
        float c = cos(a);
        return mat2(s, c, s, c);
    }
    const float PI = 3.1415;

    void main()	{
        vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);

        vec2 uvDivided = fract(newUV*vec2(1.,50.));

        vec2 uvDisplaced1 = newUV + rotate(3.1415926/1.0)*uvDivided*progress*0.1;
        vec2 uvDisplaced2 = newUV + rotate(3.1415926/1.0)*uvDivided*(1. - progress)*0.1;

        vec4 t1 = texture2D(texture1,uvDisplaced1);
        vec4 t2 = texture2D(texture2,uvDisplaced2);

        gl_FragColor = mix(t1, t2, progress);
    }
`

export const BackgroundVertexShader = `
    varying vec2 vUv;
    
    void main() {
        vUv = uv;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`
