import React, { createContext, FC, ReactNode, useState } from "react"

import { BackgroundColors } from "@types/Background"

export interface BackgroundContextProps {
  backgroundColor: BackgroundColors
  transitionToBlack: () => void
  transitionToWhite: () => void
}

const initialState: BackgroundContextProps = {
  backgroundColor: BackgroundColors.BLACK,
  transitionToBlack: () => {},
  transitionToWhite: () => {},
}

const BackgroundContext = createContext<BackgroundContextProps>(initialState)

export interface BackgroundProviderProps {
  children: ReactNode
}

const BackgroundProvider: FC<BackgroundProviderProps> = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState<BackgroundColors>(
    BackgroundColors.BLACK
  )

  // Public methods
  const transitionToBlack = () => setBackgroundColor(BackgroundColors.BLACK)
  const transitionToWhite = () => setBackgroundColor(BackgroundColors.WHITE)

  return (
    <>
      <BackgroundContext.Provider
        value={{ backgroundColor, transitionToBlack, transitionToWhite }}
      >
        {children}
      </BackgroundContext.Provider>
    </>
  )
}

export { BackgroundProvider }

export default BackgroundContext
