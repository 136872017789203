import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"

import {
  AnimatedHeadingMolecule,
  AnimatedRichTextMolecule,
} from "@common/components/molecules"
import { AnimateStates, MediaQueries } from "@common/constants"
import usePrevious from "@common/hooks/usePrevious"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { Colors } from "@constants"

export enum RSVPHeaderStates {
  INITIAL = "initial",
  ATTEND_YES = "attend-yes",
  ATTEND_NO = "attend-no",
}

export interface RSVPHeaderMoleculeProps {
  headerState: RSVPHeaderStates
  headingInit: string
  headingNo: string
  headingYes: string
  bodyTextInit: string
  bodyTextNo: string
  bodyTextYes: string
}

const RSVPHeaderMolecule: FC<
  RSVPHeaderMoleculeProps & AnimatedStateComponent
> = ({
  animate: parentAnimatedState,
  bodyTextInit,
  bodyTextNo,
  bodyTextYes,
  delay,
  headerState,
  headingInit,
  headingNo,
  headingYes,
}) => {
  const initHasSubmitted = headerState !== RSVPHeaderStates.INITIAL

  const previousHasSubmitted = usePrevious(initHasSubmitted)

  const [animate, setAnimatedState] =
    useState<AnimateStates>(parentAnimatedState)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(initHasSubmitted)

  const triggerUpdate =
    initHasSubmitted !== previousHasSubmitted &&
    previousHasSubmitted !== undefined

  const animationComplete = () => {
    if (parentAnimatedState === AnimateStates.ANIMATE_IN && triggerUpdate) {
      setHasSubmitted(initHasSubmitted)
      setAnimatedState(AnimateStates.ANIMATE_IN)
    }
  }

  useEffect(() => {
    const triggerAnimateOutOnSubmitChange =
      initHasSubmitted !== previousHasSubmitted &&
      previousHasSubmitted !== undefined &&
      parentAnimatedState === AnimateStates.ANIMATE_IN
    setAnimatedState(
      triggerAnimateOutOnSubmitChange
        ? AnimateStates.ANIMATE_OUT
        : parentAnimatedState
    )
  }, [initHasSubmitted, parentAnimatedState, previousHasSubmitted])

  return hasSubmitted ? (
    <>
      <styles.Heading animate={animate} delay={delay} size="h3">
        {headerState === RSVPHeaderStates.ATTEND_NO ? headingNo : headingYes}
      </styles.Heading>
      <styles.BodyText
        animate={animate}
        delay={delay}
        onAnimationComplete={animationComplete}
        text={
          headerState === RSVPHeaderStates.ATTEND_NO ? bodyTextNo : bodyTextYes
        }
      />
    </>
  ) : (
    <>
      <styles.Heading animate={animate} delay={delay} size="h3">
        {headingInit}
      </styles.Heading>
      <styles.BodyText
        animate={animate}
        delay={delay}
        onAnimationComplete={animationComplete}
        text={bodyTextInit}
      />
    </>
  )
}

const styles = {
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.BLACK};
    margin-bottom: ${rem(12)};

    ${MediaQueries.TABLET} {
      margin-bottom: ${rem(20)};
      max-width: 60vw;
    }

    ${MediaQueries.DESKTOP} {
      margin-bottom: ${rem(30)};
      max-width: 35vw;
    }

    ${MediaQueries.XL_DESKTOP} {
      margin-bottom: ${rem(40)};
      max-width: 30vw;
    }
  `,
  BodyText: styled(AnimatedRichTextMolecule)`
    color: ${Colors.BLACK};
    margin-bottom: ${rem(24)};

    > p {
      margin: 0 0 ${rem(24)};
    }

    ${MediaQueries.TABLET} {
      max-width: 60vw;
    }

    ${MediaQueries.DESKTOP} {
      margin-bottom: ${rem(30)};
      max-width: 35vw;
    }

    ${MediaQueries.XL_DESKTOP} {
      margin-bottom: ${rem(36)};
      max-width: 30vw;
    }
  `,
}

export default RSVPHeaderMolecule
