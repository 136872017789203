import { useState } from "react"

import { delay } from "@common/utils/DelayUtils"

export enum LoadingStates {
  INITIAL = "initial",
  LOADING = "loading",
  COMPLETE = "complete",
}

export interface PreloaderResponseProps {
  loadingState: LoadingStates
}

// TODO: How to load an image within a promise
const loadImage = (url: string) => {
  const image = new Image()
  const removeEventListeners = () => {
    image.removeEventListener("load", onLoad)
    image.removeEventListener("error", onError)
  }
  const onLoad = () => {
    removeEventListeners()

    return new Promise(() => image)
  }
  const onError = error => {
    removeEventListeners()

    new Promise(() => error)
  }
  image.addEventListener("load", onLoad)
  image.addEventListener("error", onError)
  image.src = url
}

const load = async (sources: string[]): Promise<void> =>
  await sources.forEach(async url => {
    // TODO: Add switch to support loadVideo
    const response = await loadImage(url)
    return response
  })

let shouldLoad = true

const usePreloader = (assets: string[]): PreloaderResponseProps => {
  const [loadingState, setIsLoaded] = useState(LoadingStates.INITIAL)

  const loadAssets = async (sources: string[]) => {
    await delay(250)
    setIsLoaded(LoadingStates.LOADING)
    await load(sources)
    await delay(2000)
    setIsLoaded(LoadingStates.COMPLETE)
  }

  if (shouldLoad && assets?.length > 0) {
    loadAssets(assets)
    shouldLoad = false
  }

  return { loadingState }
}

export default usePreloader
