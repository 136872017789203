import { useEffect, useState } from "react"

export type WindowSize = {
  width?: number
  height?: number
}

export const isClient: boolean = typeof window === "object"

let $body: HTMLElement

const getSize = (): WindowSize => {
  const viewportHeight = isClient ? window?.innerHeight : 0
  const viewportWidth = isClient ? window?.innerWidth : 0

  if (isClient) {
    if ($body === undefined) {
      $body = window.document.querySelector("body") as unknown as HTMLElement
    }

    $body.style.setProperty("--viewportHeight", `${viewportHeight}px`)
    $body.style.setProperty("--viewportWidth", `${viewportWidth}px`)
  }

  return {
    width: isClient ? viewportWidth : undefined,
    height: isClient ? viewportHeight : undefined,
  }
}

const useViewportObserver = () => {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export default useViewportObserver
