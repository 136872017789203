import React, { FC, ReactNode } from "react"
import styled from "styled-components"

export interface FormAtomProps {
  className?: string
  children?: ReactNode
}

const FormAtom: FC<FormAtomProps & HTMLFormElement> = ({
  className,
  children,
}) => <styles.Form className={className}>{children}</styles.Form>

const styles = {
  Form: styled.form``,
}

export default FormAtom
