const INTRO_SEEN = "introSeen"

const isClient = typeof window === "object"

export const hasSeenIntro = () => {
  if (isClient) {
    return document.cookie.includes(`${INTRO_SEEN}`)
  } else {
    console.warn("Cookies are not available in this environment.")
    return true
  }
}

export const setHasSeenIntro = (value: string) => {
  if (isClient) {
    document.cookie = `${INTRO_SEEN}=${value}; path=/`
  } else {
    console.warn("Cookies are not available in this environment.")
  }
}
