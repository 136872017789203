import React, { FC } from "react"
import { Variants } from "framer-motion"
import styled from "styled-components"

import {
  AnimatedSplitTextAtom,
  LinkAtom,
  TextAtom,
} from "@common/components/atoms"
import { AnimatedSplitTextAtomProps } from "@common/components/atoms/AnimatedSplitTextAtom"
import { LinkAtomProps } from "@common/components/atoms/LinkAtom"
import { AnimateStates } from "@common/constants"
import { rem } from "@common/utils"

import { Colors, Easings } from "@constants"

export const variants = {
  [AnimateStates.INITIAL]: ({ delay = 0 }): unknown => ({
    scale: 0,
    transition: {
      delay,
      duration: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({ delay = 0 }): unknown => ({
    scale: 1,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseOut,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay = 0 }) => ({
    scale: 0,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseOut,
    },
  }),
} as Variants

export interface ArtworkLinkMoleculeProps {
  className?: string
  text: string
}

const ArtworkLinkMolecule: FC<
  ArtworkLinkMoleculeProps & LinkAtomProps & AnimatedSplitTextAtomProps
> = ({
  animate,
  className,
  delay,
  direction,
  duration,
  href,
  target,
  text,
}) => (
  <styles.Link className={className} href={href} target={target}>
    <styles.Text>
      <AnimatedSplitTextAtom
        animate={animate}
        delay={delay + 0.05}
        direction={direction}
        duration={duration}
        step={0.03}
      >
        {text}
      </AnimatedSplitTextAtom>
    </styles.Text>
  </styles.Link>
)

const styles = {
  Link: styled(LinkAtom)`
    display: flex;
  `,
  Text: styled(TextAtom)`
    color: ${Colors.WHITE};
    line-height: ${rem(20)};
    letter-spacing: ${rem(-1.05)};
    margin: auto ${rem(18)} auto 0;
    position: relative;
    top: ${rem(2)};
    white-space: pre-line;
  `,
}

export default ArtworkLinkMolecule
