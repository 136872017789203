import React, { FC, createElement, useEffect, useRef } from "react"
import styled from "styled-components"

import { AnimateStates, MediaQueries } from "@common/constants"
import { useScroll, useStepper } from "@common/hooks"
import { ScrollDirection } from "@common/hooks/useScroll"
import { isClient } from "@common/hooks/useViewportObserver"
import { rem, publish } from "@common/utils"

import { SlideIndicatorMolecule } from "@components/molecules"
import {
  HomeAgendaOrganism,
  HomeFAQOrganism,
  HomeHeroOrganism,
  HomeIntroductionOrganism,
  HomeLocationOrganism,
  HomeRSVPOrganism,
} from "@components/organisms"

import { Colors } from "@constants"

import { WebGLEvents } from "@gfx/WebGL"

import { useBackground } from "@hooks"

import { HomeSection, SectionItem } from "@types/HomeSection"

import { getSize } from "@utils/MediaQueryUtils"

export const HomeSectionNames = {
  HOME: "home",
  INTRODUCTION: "introduction",
  AGENDA: "agenda",
  LOCATION: "location",
  FAQ: "faq",
  RSVP: "rsvp",
}

const HomeSections = {
  [HomeSectionNames.HOME]: HomeHeroOrganism,
  [HomeSectionNames.INTRODUCTION]: HomeIntroductionOrganism,
  [HomeSectionNames.AGENDA]: HomeAgendaOrganism,
  [HomeSectionNames.LOCATION]: HomeLocationOrganism,
  [HomeSectionNames.FAQ]: HomeFAQOrganism,
  [HomeSectionNames.RSVP]: HomeRSVPOrganism,
}

export interface HomeTemplateProps {
  pageContext: {
    assets: any[]
    dictionary: { [key: string]: string }
    items: HomeSection[]
    sectionItems: SectionItem[]
  }
}

const getIndexFromHash = (sectionItems: { id: string }[]) => {
  if (isClient) {
    const hash = window.location.hash.substring(1)?.toLocaleLowerCase()
    return sectionItems.findIndex(item => item.id === hash)
  }
  return 0
}

const HomeTemplate: FC<HomeTemplateProps> = ({
  pageContext: { assets, dictionary, items, sectionItems },
}) => {
  const { transitionToBlack, transitionToWhite } = useBackground()
  const { goBack, goForward, goTo, previousStep, step } = useStepper(
    Math.max(getIndexFromHash(sectionItems), 0),
    items?.length
  )
  const isEnabledRef = useRef<boolean>(true)
  const directionRef = useRef<ScrollDirection>()

  useScroll((direction: ScrollDirection) => {
    if (!isEnabledRef.current) return
    isEnabledRef.current = false

    directionRef.current = direction

    if (direction === ScrollDirection.UP) {
      goBack()
    } else if (direction === ScrollDirection.DOWN) {
      goForward()
    }

    setTimeout(() => {
      isEnabledRef.current = true
    }, 1100)
  })

  const hashChangeHandler = () => goTo(getIndexFromHash(sectionItems))

  useEffect(() => {
    if (isClient) {
      window.location.hash = sectionItems[step]?.id
    }

    if (sectionItems[step]?.id === HomeSectionNames.RSVP) {
      transitionToWhite()
    } else {
      transitionToBlack()
    }
  }, [step])

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler)

    return () => {
      window.removeEventListener("hashchange", hashChangeHandler)
    }
  }, [])

  if (isClient && previousStep !== step) {
    const size = getSize()
    publish(
      WebGLEvents.UPDATE,
      sectionItems[step]?.id === HomeSectionNames.RSVP
        ? `${size}_white`
        : assets[step][size].background.slug,
      sectionItems[step]?.id === HomeSectionNames.RSVP || size === "mobile"
        ? null
        : assets[step][size].particle.slug
    )
  }

  const color =
    sectionItems[step]?.id === HomeSectionNames.RSVP
      ? Colors.BLACK
      : Colors.WHITE

  return (
    <styles.Container>
      {items?.map((item: HomeSection, index: number) =>
        HomeSections[item.slug] !== undefined
          ? createElement(HomeSections[item.slug], {
              key: item.slug,
              animate:
                previousStep === index
                  ? AnimateStates.ANIMATE_OUT
                  : step === index
                  ? AnimateStates.ANIMATE_IN
                  : AnimateStates.INITIAL,
              dictionary,
              ...item,
            })
          : null
      )}
      <styles.SlideIndicator
        activeSlide={step}
        color={color}
        items={sectionItems}
      />
    </styles.Container>
  )
}

const styles = {
  Container: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--viewportHeight);
  `,
  SlideIndicator: styled(SlideIndicatorMolecule)`
    display: none;

    ${MediaQueries.TABLET} {
      display: flex;
      left: auto;
      position: absolute;
      right: ${rem(36)};
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  `,
}

export default HomeTemplate
