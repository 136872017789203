import React, { FC } from "react"
import styled from "styled-components"

import { HeadingAtom } from "@common/components/atoms"
import { BreakpointUnits } from "@common/constants"

import { Colors, Layers } from "@constants"

export interface RotateDeviceMoleculeProps {
  heading: string
}

const RotateDeviceMolecule: FC<RotateDeviceMoleculeProps> = ({ heading }) => (
  <styles.Container>
    <styles.Heading size="h2">{heading}</styles.Heading>
  </styles.Container>
)

const styles = {
  Container: styled.div`
    display: none;

    @media only screen and (max-width: ${BreakpointUnits.TABLET -
      1}px) and (orientation: landscape) {
      align-items: center;
      background-color: ${Colors.BLACK};
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: ${Layers.ROTATE_DEVICE_WARNING};
    }
  `,
  Heading: styled(HeadingAtom)`
    color: ${Colors.WHITE};
    margin: auto;
    max-width: 375px;
    padding: 5vw;
    text-align: center;
  `,
}

export default RotateDeviceMolecule
