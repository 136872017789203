export const delay = (ms: number): unknown =>
  new Promise(_ => setTimeout(_, ms))

export const throttle = (mainFunction: (any?: []) => void, delay: number) => {
  let timerFlag: ReturnType<typeof setTimeout> | null = null

  return (...args: any[]) => {
    if (timerFlag === null) {
      mainFunction(...args)
      timerFlag = setTimeout(() => {
        timerFlag = null
      }, delay)
    }
  }
}
