import React, { ChangeEventHandler, FocusEventHandler, forwardRef } from "react"
import styled from "styled-components"

export interface InputAtomProps {
  autoComplete?: string
  className?: string
  disabled?: boolean
  onBlur: FocusEventHandler<HTMLInputElement> | undefined
  onChange: ChangeEventHandler<HTMLInputElement> | undefined
  onFocus: FocusEventHandler<HTMLInputElement> | undefined
  value: string | number | readonly string[] | undefined
  type: "email" | "text"
}

const InputAtom = forwardRef<HTMLInputElement, InputAtomProps>(
  (
    {
      autoComplete = "one-time-code",
      className,
      disabled,
      onBlur,
      onChange,
      onFocus,
      type,
      value,
    },
    ref
  ) => (
    <styles.Input
      autoComplete={autoComplete}
      className={className}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      ref={ref}
      type={type}
      value={value}
    />
  )
)

const styles = {
  Input: styled.input`
    background-color: transparent;
    border: 0;
    padding: 0;
  `,
}

export default InputAtom
