import React, { FC, useState } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import { FormAtom } from "@common/components/atoms"
import { AnimateStates, MediaQueries } from "@common/constants"
import usePrevious from "@common/hooks/usePrevious"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import {
  ButtonMolecule,
  RSVPFieldset1Molecule,
  RSVPFieldset2Molecule,
} from "@components/molecules"
import { HomeRSVPOrganismProps } from "@components/organisms/HomeRSVPOrganism"

import { Colors } from "@constants"

import { RSVPAttendingOptions } from "@utils/schemas/RSVPSchema"

export interface RSVPFormMoleculeProps {
  backButtonLabel: string
  isSingleForm: boolean
  isStaying: boolean
  isSubmitting: boolean
  nextButtonLabel: string
  onBack: () => void
  onSubmit: () => void
  step: number
  submitButtonLabel: string
  attending?: RSVPAttendingOptions
}

const RSVPFormMolecule: FC<
  RSVPFormMoleculeProps & HomeRSVPOrganismProps & AnimatedStateComponent
> = ({
  accommodationLabel,
  accommodationOptionCampervan,
  accommodationOptionFamily,
  accommodationOptionHotel,
  accommodationOptionNotStaying,
  accommodationOptionTent,
  animate,
  backButtonLabel,
  delay,
  durationOptionFridayAndLonger,
  durationOptionFridayToMonday,
  durationOptionFridayToSunday,
  durationOptionThursdayAndLonger,
  durationOptionThursdayToMonday,
  durationOptionThursdayToSunday,
  durationLabel,
  emailLabel,
  isSingleForm,
  isStaying,
  isSubmitting,
  nameLabel,
  nextButtonLabel,
  onBack,
  onSubmit,
  step,
  submitButtonLabel,
  transportationLabel,
  transportationOptionCar,
  transportationOptionTrain,
  attending,
  willAttendLabel,
  willAttendOptionNo,
  willAttendOptionYes,
}) => {
  const x = isSingleForm && step === 1 ? "-100%" : "0%"
  const previousStep = usePrevious(step)

  const [key, setKey] = useState<number>(0)

  let animateFieldset1 = animate,
    animateFieldset2 = AnimateStates.INITIAL,
    delayFieldset1 = delay,
    delayFieldset2 = 0

  if (isSingleForm && previousStep !== undefined && previousStep !== step) {
    animateFieldset1 =
      step === 0 ? AnimateStates.ANIMATE_IN : AnimateStates.ANIMATE_OUT
    animateFieldset2 =
      step === 0 ? AnimateStates.ANIMATE_OUT : AnimateStates.ANIMATE_IN
    delayFieldset1 = step === 0 ? 0.5 : 0
    delayFieldset2 = step === 0 ? 0 : 0.5
  } else if (!isSingleForm) {
    if (attending === RSVPAttendingOptions.YES) {
      animateFieldset2 = animate
      delayFieldset2 = 0
    }
  }

  // Force animate out if ANIMATE_OUT
  if (animate === AnimateStates.ANIMATE_OUT) {
    animateFieldset1 = animateFieldset2 = animate
    delayFieldset1 = 0
    delayFieldset2 = 0.1
  }

  return (
    <FormAtom>
      <styles.FormContainer
        animate={{ x }}
        transition={{
          duration: 0,
          delay: 0.6,
        }}
      >
        <styles.Fieldset1
          animate={animateFieldset1}
          delay={delayFieldset1}
          emailLabel={emailLabel}
          keyClick={key}
          nameLabel={nameLabel}
          willAttendLabel={willAttendLabel}
          willAttendOptionYes={willAttendOptionYes}
          willAttendOptionNo={willAttendOptionNo}
        />
        <styles.Fieldset2
          accommodationLabel={accommodationLabel}
          accommodationOptionCampervan={accommodationOptionCampervan}
          accommodationOptionFamily={accommodationOptionFamily}
          accommodationOptionHotel={accommodationOptionHotel}
          accommodationOptionNotStaying={accommodationOptionNotStaying}
          accommodationOptionTent={accommodationOptionTent}
          animate={animateFieldset2}
          delay={delayFieldset2}
          durationOptionFridayAndLonger={durationOptionFridayAndLonger}
          durationOptionFridayToMonday={durationOptionFridayToMonday}
          durationOptionFridayToSunday={durationOptionFridayToSunday}
          durationOptionThursdayAndLonger={durationOptionThursdayAndLonger}
          durationOptionThursdayToMonday={durationOptionThursdayToMonday}
          durationOptionThursdayToSunday={durationOptionThursdayToSunday}
          durationLabel={durationLabel}
          isStaying={isStaying}
          keyClick={key}
          transportationLabel={transportationLabel}
          transportationOptionCar={transportationOptionCar}
          transportationOptionTrain={transportationOptionTrain}
        />
      </styles.FormContainer>
      <div>
        {isSingleForm && step === 1 && (
          <styles.BackButton
            animate={animate}
            color={Colors.WHITE}
            delay={delay + (animate === AnimateStates.ANIMATE_IN ? 0.6 : 0)}
            onClick={onBack}
            text={backButtonLabel}
          />
        )}
        <ButtonMolecule
          animate={animate}
          color={Colors.BLACK}
          delay={delay + (animate === AnimateStates.ANIMATE_IN ? 0.3 : 0)}
          disabled={isSubmitting}
          onClick={() => {
            onSubmit()
            setKey(previous => previous + 1)
          }}
          text={
            isSingleForm && attending === RSVPAttendingOptions.YES && step === 0
              ? nextButtonLabel
              : submitButtonLabel
          }
        />
      </div>
    </FormAtom>
  )
}

const styles = {
  FormContainer: styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    ${MediaQueries.TABLET} {
      width: 100%;
    }
  `,
  Fieldset1: styled(RSVPFieldset1Molecule)`
    ${MediaQueries.TABLET} {
      flex: 50%;
    }
  `,
  Fieldset2: styled(RSVPFieldset2Molecule)`
    ${MediaQueries.TABLET} {
      flex: 50%;
    }
  `,
  BackButton: styled(ButtonMolecule)`
    margin-right: ${rem(16)};
  `,
}

export default RSVPFormMolecule
