import { Texture, TextureLoader } from "three"

import { TextureInfo } from "@types/Background"

export interface LoaderRequest {
  assets: { publicUrl: string; slug: string }[]
  onComplete: () => void
  onError: () => void
  onProgress: (progress: number, textureId: string) => void
}

export interface LoaderResponse {
  start: (assets: { background: TextureInfo; particle?: TextureInfo }[]) => void
}

let _textures: { [key: string]: Texture } = {}

const getTotalTextures = (
  assets: { background: TextureInfo; particle?: TextureInfo }[]
): number => {
  let count = 0
  assets.forEach(asset => {
    count += Object.values(asset).filter(
      val => val !== undefined && val !== null
    ).length
  })
  return count
}

const Loader = ({
  onComplete,
  onError,
  onProgress,
}: LoaderRequest): LoaderResponse => {
  let _isLoading = false

  const start = async (
    assets: { background: TextureInfo; particle?: TextureInfo }[]
  ) => {
    if (_isLoading) return

    _isLoading = true

    const totalTextures = getTotalTextures(assets)
    let loadedTextures = 0

    try {
      for (const { background, particle } of assets) {
        if (background) {
          await new Promise((resolve, reject) => {
            new TextureLoader().load(
              background.publicUrl,
              (texture: Texture) => {
                _textures[background.slug] = texture
                loadedTextures++
                onProgress(loadedTextures / totalTextures, background.slug)
                resolve(texture)
              },
              undefined,
              reject
            )
          })
        }
        if (particle) {
          await new Promise((resolve, reject) => {
            new TextureLoader().load(
              particle.publicUrl,
              (texture: Texture) => {
                _textures[particle.slug] = texture
                loadedTextures++
                onProgress(loadedTextures / totalTextures, particle.slug)
                resolve(texture)
              },
              undefined,
              reject
            )
          })
        }
      }

      onComplete()
    } catch (error) {
      console.log(error)
      onError()
    } finally {
      _isLoading = false
    }
  }

  return {
    start,
  }
}

export const getTextures = (): { [key: string]: Texture } => _textures

export default Loader
