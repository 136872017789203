import React, { FC } from "react"
import styled from "styled-components"

import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import {
  FormSelectFieldMolecule,
  FormTextFieldMolecule,
} from "@components/molecules"

import { RSVPAttendingOptions } from "@utils/schemas/RSVPSchema"

export interface RSVPFieldset1MoleculeProps {
  className?: string
  emailLabel: string
  keyClick: number
  nameLabel: string
  willAttendLabel: string
  willAttendOptionNo: string
  willAttendOptionYes: string
}

// TODO: Move to util
const ANIMATE_IN_DELAY = 0.5
export const getDelay = (
  animate: AnimateStates,
  delay: number,
  step: number
): number =>
  animate === AnimateStates.INITIAL
    ? 0
    : animate === animate.ANIMATE_IN
    ? delay + ANIMATE_IN_DELAY + step
    : delay + step

const RSVPFieldset1Molecule: FC<
  RSVPFieldset1MoleculeProps & AnimatedStateComponent
> = ({
  animate,
  className,
  delay = 0,
  emailLabel,
  keyClick = 0,
  nameLabel,
  willAttendLabel,
  willAttendOptionYes,
  willAttendOptionNo,
}) => (
  <styles.Fieldset
    $isEnabled={animate === AnimateStates.ANIMATE_IN}
    className={className}
  >
    <FormTextFieldMolecule
      animate={animate}
      delay={getDelay(animate, delay, 0.05)}
      keyClick={keyClick}
      label={nameLabel}
      name="name"
    />
    <FormTextFieldMolecule
      animate={animate}
      delay={getDelay(animate, delay, 0.1)}
      keyClick={keyClick}
      label={emailLabel}
      name="email"
    />
    <FormSelectFieldMolecule
      animate={animate}
      delay={getDelay(animate, delay, 0.15)}
      items={[
        { value: RSVPAttendingOptions.EMPTY, label: "" },
        { value: RSVPAttendingOptions.YES, label: willAttendOptionYes },
        { value: RSVPAttendingOptions.NO, label: willAttendOptionNo },
      ]}
      keyClick={keyClick}
      label={willAttendLabel}
      name="attending"
    />
  </styles.Fieldset>
)

const styles = {
  Fieldset: styled.fieldset<{ $isEnabled: boolean }>`
    border: 0;
    flex: 100%;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};

    ${MediaQueries.TABLET} {
      margin-right: ${rem(20)};
      max-width: 45%;
    }

    ${MediaQueries.DESKTOP} {
      margin-right: ${rem(30)};
      max-width: 40%;
    }

    ${MediaQueries.XL_DESKTOP} {
      margin-right: ${rem(40)};
      max-width: ${rem(430)};
    }
  `,
}

export default RSVPFieldset1Molecule
