import React, { FC, Fragment, ReactNode } from "react"
import { WindowLocation } from "@reach/router"
import { AnimatePresence } from "framer-motion"

import { GlobalStylesAtom } from "@common/components/atoms"
import { MotionWrapperOrganism } from "@common/components/organisms"

import { ApplicationProvider } from "@common/contexts/ApplicationContext"

import { useApplication } from "@common/hooks"

import { PreloaderComponent } from "@common/types"

export interface ApplicationOrganismProps {
  background?: ReactNode
  children: ReactNode
  location: WindowLocation
  navigation?: ReactNode
  pageContext: any
  preloaderComponent?: PreloaderComponent
  providerComponent?: FC
}

const ApplicationBodyOrganism: FC<ApplicationOrganismProps> = ({
  background,
  children,
  location,
  navigation,
  pageContext,
  preloaderComponent: PreloaderComponent,
}) => {
  const { hasLoaded, setHasLoaded } = useApplication()

  return (
    <>
      <GlobalStylesAtom />
      {background}
      {hasLoaded && (
        <>
          {navigation}
          <AnimatePresence mode="wait">
            <MotionWrapperOrganism key={location.pathname}>
              {children}
            </MotionWrapperOrganism>
          </AnimatePresence>
        </>
      )}
      {PreloaderComponent && !hasLoaded && (
        <PreloaderComponent
          assets={pageContext?.assets ?? []}
          dictionary={pageContext?.dictionary ?? {}}
          onHide={() => setHasLoaded(true)}
        />
      )}
    </>
  )
}

const ApplicationOrganism: FC<ApplicationOrganismProps> = ({
  background,
  children,
  location,
  navigation,
  pageContext,
  preloaderComponent,
  providerComponent: ProviderComponent = Fragment,
}) => (
  <ApplicationProvider>
    <ProviderComponent>
      <ApplicationBodyOrganism
        background={background}
        children={children}
        location={location}
        navigation={navigation}
        pageContext={pageContext}
        preloaderComponent={preloaderComponent}
      />
    </ProviderComponent>
  </ApplicationProvider>
)

export default ApplicationOrganism
