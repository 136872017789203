import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import { AnimatedHeadingMolecule } from "@common/components/molecules"
import { useViewportObserver } from "@common/hooks"
import { isClient } from "@common/hooks/useViewportObserver"
import { AnimateStates, MediaQueries } from "@common/constants"
import { delay, off, on, publish, rem } from "@common/utils"

import { ButtonMolecule } from "@components/molecules"
import { ButtonVariants } from "@components/molecules/ButtonMolecule"

import { Colors, hasSeenIntro, Layers, setHasSeenIntro, Typography } from "@constants"

import Loader from "@gfx/Loader"
import { WebGLEvents } from "@gfx/WebGL"

import { BackgroundSlide } from "@types/Background"

import { getSize, isTouch } from "@utils/MediaQueryUtils"

let isTHREEReady: boolean = false,
  homeAssetsLoaded: number = 0,
  timeFromStart: Date

interface PreloaderOrganismProps {
  assets: string[]
  dictionary: { [key: string]: string }
  onHide: () => void
}

enum EventOptions {
  CHRIS = "chris",
  WIJNAND = "wijnand",
}

const PreloaderOrganism: FC<PreloaderOrganismProps> = ({
  assets,
  dictionary,
  onHide,
}) => {
  const HAS_SEEN_INTRO = hasSeenIntro()

  const { width, height } = useViewportObserver()

  const [pathLength, setPathLength] = useState<number>(0)
  const [slide, setSlide] = useState<number>(0)
  const [animateSlide, setAnimateSlide] = useState<AnimateStates>(
    AnimateStates.INITIAL
  )
  const [animateButtons, setAnimateButtons] = useState<AnimateStates>(
    AnimateStates.INITIAL
  )
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const isIntroReady = useRef<boolean>(HAS_SEEN_INTRO)

  const text = useMemo(
    () => [
      dictionary?.preloaderHeading1,
      dictionary?.preloaderHeading2,
      dictionary?.preloaderHeading3,
      dictionary?.preloaderHeading4,
      dictionary?.preloaderHeading5,
      dictionary?.preloaderHeading6,
    ],
    [dictionary]
  )

  // Private events
  const playSlide = async (showTextDelay: number, animationId: string) => {
    setAnimateSlide(AnimateStates.ANIMATE_IN)

    if (animationId === EventOptions.CHRIS) {
      publish(WebGLEvents.START_JITTER, EventOptions.CHRIS)
    } else if (animationId === EventOptions.WIJNAND) {
      publish(WebGLEvents.START_JITTER, EventOptions.WIJNAND)
    } else {
      publish(WebGLEvents.STOP_JITTER)
    }

    await delay(showTextDelay)
    setAnimateSlide(AnimateStates.ANIMATE_OUT)
    await delay(1000)

    setSlide(previous => previous + 1)
  }

  const playIntro = async () => {
    await playSlide(3300, EventOptions.CHRIS)
    await playSlide(3300, EventOptions.WIJNAND)
    setAnimateSlide(AnimateStates.ANIMATE_IN)
    await delay(150)
    setAnimateButtons(AnimateStates.ANIMATE_IN)
    await delay(500)
    setIsEnabled(true)
  }

  const playOutro = async (value: EventOptions) => {    
    setAnimateButtons(AnimateStates.ANIMATE_OUT)
    setAnimateSlide(AnimateStates.ANIMATE_OUT)

    await delay(1000)

    setSlide(previous => previous + 1)

    await playSlide(3300, "")
    await playSlide(3300, "")

    setHasSeenIntro(value)
    onHide()
  }

  const checkCanPlayAfterFirstSlide = async () => {
    const minItemsToLoad = getSize() === "desktop" ? 2 : 1

    if (
      isTHREEReady &&
      homeAssetsLoaded >= minItemsToLoad &&
      !HAS_SEEN_INTRO &&
      !isIntroReady.current
    ) {
      isIntroReady.current = true

      const timeNow = new Date(),
        difference = timeNow.getTime() - timeFromStart.getTime(),
        timeToWait = 5400 - difference

      await delay(timeToWait > 0 ? timeToWait : 0)

      setAnimateSlide(AnimateStates.ANIMATE_OUT)
      await delay(1000)
      setSlide(previous => previous + 1)

      playIntro()
    }
  }

  const loadAssets = () => {
    const { start } = Loader({
      onComplete: async () => {
        if (HAS_SEEN_INTRO) {
          await delay(1000)
          onHide()
        }
      },
      onError: () => {
        //
      },
      onProgress: (progress: number, textureId: string) => {
        if (textureId.indexOf("_home_") !== -1) {
          homeAssetsLoaded++
          checkCanPlayAfterFirstSlide()
        }

        setPathLength(progress)
      },
    })

    if (isClient) {
      const size = getSize(),
        items = assets.map((item: BackgroundSlide) => item[size])
      start(items, size)
    } else {
      start([])
    }
  }

  // Event handelers
  const canvasReadyHandler = () => {
    isTHREEReady = true

    if (!HAS_SEEN_INTRO) {
      checkCanPlayAfterFirstSlide()
    }
  }

  const mouseOverHandler = (option: EventOptions) => {
    if (!isTouch()) {
      publish(WebGLEvents.START_JITTER, option)
    }
  }

  const mouseOutHandler = (option: EventOptions) => {
    if (!isTouch()) {
      publish(WebGLEvents.STOP_JITTER, option)
    }
  }

  // Effect events
  useEffect(() => {
    if (isClient) {
      try {
        document.querySelector("#___gatsby")?.classList?.remove("hidden")
      } catch (error) {
        console.warn("Gatsby element does not exist")
      }
    }

    on(WebGLEvents.READY, canvasReadyHandler)

    timeFromStart = new Date()

    loadAssets()

    const triggerIntro = async () => {
      if (!HAS_SEEN_INTRO) {
        await delay(500)

        setAnimateSlide(AnimateStates.ANIMATE_IN)
      }
    }
    triggerIntro()

    return () => {
      off(WebGLEvents.READY, canvasReadyHandler)
    }
  }, [])

  // TODO: Put this into a component
  const borderSize = isClient
    ? parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    : 16

  return (
    <styles.Container>
      <styles.Border
        height={height}
        width={width}
        viewBox={`0 0 ${width} ${height}`}
      >
        <styles.Path
          d={`M${borderSize},${borderSize} ${
            width - borderSize
          },${borderSize} ${width - borderSize},${
            height - borderSize
          } ${borderSize},${height - borderSize} T${borderSize},${
            borderSize - 1
          }`}
          fill="none"
          shapeRendering="crispEdges"
          stroke={Colors.WHITE}
          strokeWidth="2"
          initial={{
            pathLength: 0,
          }}
          animate={{
            pathLength,
          }}
          transition={{
            duration: 1,
          }}
        />
      </styles.Border>
      {!HAS_SEEN_INTRO && (
        <styles.Inner>
          <styles.Heading animate={animateSlide} size="h2" key={slide}>
            {text[slide]}
          </styles.Heading>
          <styles.Buttons $isEnabled={isEnabled}>
            <styles.ButtonChris
              animate={animateButtons}
              delay={0}
              onClick={() => playOutro(EventOptions.CHRIS)}
              onMouseOut={() => mouseOutHandler(EventOptions.CHRIS)}
              onMouseOver={() => mouseOverHandler(EventOptions.CHRIS)}
              text={dictionary?.preloaderButton1}
              variant={ButtonVariants.SECONDARY}
            />
            <styles.ButtonWijnand
              animate={animateButtons}
              delay={0.2}
              onClick={() => playOutro(EventOptions.WIJNAND)}
              onMouseOut={() => mouseOutHandler(EventOptions.WIJNAND)}
              onMouseOver={() => mouseOverHandler(EventOptions.WIJNAND)}
              text={dictionary?.preloaderButton2}
              variant={ButtonVariants.SECONDARY}
            />
          </styles.Buttons>
        </styles.Inner>
      )}
    </styles.Container>
  )
}

const styles = {
  Container: styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${Layers.PRELOADER};
    overflow: hidden;
    position: fixed;
    -webkit-backface-visibility: hidden;
  `,
  Border: styled.svg`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  `,
  Path: motion.path,
  Inner: styled.div`
    padding: ${rem(40)} ${rem(28)};
    position: relative;

    ${MediaQueries.TABLET} {
      max-width: 50vw;
    }

    ${MediaQueries.XL_DESKTOP} {
      max-width: 33vw;
    }
  `,
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    font-family: "${Typography.FONT_FAMILY_BODY}";
    margin: auto;
    max-width: 72vw;
    text-align: center;
  `,
  Buttons: styled.div<{ $isEnabled: boolean }>`
    display: flex;
    flex-direction: column;
    left: ${rem(32)};
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
    position: absolute;
    right: ${rem(32)};
    top: 100%;

    ${MediaQueries.TABLET} {
      flex-direction: row;
    }
  `,
  ButtonChris: styled(ButtonMolecule)`
    button {
      justify-content: center;
      max-width: ${rem(600)};
      width: 100%;
    }

    ${MediaQueries.TABLET} {
      margin: auto ${rem(10)} auto auto;

      button {
        width: auto;
      }
    }
  `,
  ButtonWijnand: styled(ButtonMolecule)`
    margin-top: ${rem(16)};

    button {
      justify-content: center;
      max-width: ${rem(600)};
      width: 100%;
    }

    ${MediaQueries.TABLET} {
      margin: auto auto auto ${rem(10)};

      button {
        width: auto;
      }
    }
  `,
}

export default PreloaderOrganism
