import { RefObject, useEffect, useRef, useState } from "react"
import { useInView } from "framer-motion"

import AnimateStates from "@common/constants/AnimateStates"

export interface AnimateInViewProps {
  amount?: number
  hasLoaded: boolean
}

export interface AnimateInViewResponseProps {
  animate: AnimateStates
  ref: RefObject<HTMLElement>
}

const useAnimateInView = ({
  amount = 0.25,
  hasLoaded,
}: AnimateInViewProps): AnimateInViewResponseProps => {
  const ref = useRef<HTMLElement>(null)
  const isInView = useInView(ref, { once: true, amount })
  const [animate, setAnimate] = useState<AnimateStates>(
    AnimateStates.INITIAL
  )

  const inViewChangeHandler = () => {
    if (hasLoaded && isInView) {
      setAnimate(AnimateStates.ANIMATE_IN)
    }
  }

  useEffect(inViewChangeHandler, [hasLoaded, isInView])

  return {
    animate,
    ref,
  }
}

export default useAnimateInView
