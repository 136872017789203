import React, { FC, ReactNode } from "react"
import styled from "styled-components"

export interface ListAtomProps {
  children: ReactNode
  className?: string
}

const ListAtom: FC<ListAtomProps & HTMLUListElement> = ({
  children,
  className,
}) => <styles.List className={className}>{children}</styles.List>

const styles = {
  List: styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
  `,
}

export default ListAtom
