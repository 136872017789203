import React, { FC, useEffect, useRef } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import { useApplication, useViewportObserver } from "@common/hooks"
import { isClient } from "@common/hooks/useViewportObserver"
import { publish } from "@common/utils"

import { Colors, Layers } from "@constants"

import { WebGLEvents } from "@gfx/WebGL"

import { useBackground } from "@hooks"

import { BackgroundColors } from "@types/Background"

export interface BackgroundOrganismProps {}

const BackgroundOrganism: FC<BackgroundOrganismProps> = () => {
  const { hasLoaded } = useApplication()
  const { backgroundColor } = useBackground()
  const { width, height } = useViewportObserver()

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const loadCanvas = async () => {
      const { default: WebGL } = await import("@gfx/WebGL")
      WebGL.init(canvasRef.current as HTMLCanvasElement)

      publish(WebGLEvents.READY)
    }
    loadCanvas()
  }, [])

  // TODO: Put this into a component
  const borderSize = isClient
    ? parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    : 16

  return (
    <styles.Container>
      <styles.Canvas ref={canvasRef} />
      {hasLoaded && (
        <styles.Border
          height={height}
          width={width}
          viewBox={`0 0 ${width} ${height}`}
        >
          <styles.Path
            d={`M${borderSize},${borderSize} ${
              width - borderSize
            },${borderSize} ${width - borderSize},${
              height - borderSize
            } ${borderSize},${height - borderSize} T${borderSize},${
              borderSize - 1
            }`}
            fill="none"
            shapeRendering="crispEdges"
            stroke={
              backgroundColor === BackgroundColors.BLACK
                ? Colors.WHITE
                : Colors.BLACK
            }
            strokeWidth="2"
          />
        </styles.Border>
      )}
    </styles.Container>
  )
}

const styles = {
  Container: styled.div`
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${Layers.BACKGROUND};
    overflow: hidden;
    position: fixed;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
  `,
  Canvas: styled.canvas`
    left: 0;
    position: absolute;
    top: 0;
  `,
  Border: styled.svg`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  `,
  Path: styled(motion.path)``,
}

export default BackgroundOrganism
