import React, { forwardRef } from "react"
import styled from "styled-components"

export interface ArrowRightAtomProps {
  className?: string
  pathLength?: number
  stroke?: string
}

const ArrowRightAtom = forwardRef<SVGElement, ArrowRightAtomProps>(
  ({ className, pathLength = 1, stroke }, ref) => {
    return (
      <styles.Svg
        className={className}
        width="41"
        height="19"
        viewBox="0 0 41 19"
        ref={ref}
      >
        <polyline
          fill="none"
          stroke={stroke}
          strokeLinejoin="bevel"
          strokeWidth="2"
          pathLength={pathLength}
          points="60 9 100 9 90 0 100 9 90 17.092"
          transform="translate(-60 1)"
        />
      </styles.Svg>
    )
  }
)

const styles = {
  Svg: styled.svg``,
}

export default ArrowRightAtom
