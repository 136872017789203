import React, {
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
  forwardRef,
} from "react"
import styled from "styled-components"

export interface SelectAtomProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  onBlur: FocusEventHandler<HTMLSelectElement> | undefined
  onChange: ChangeEventHandler<HTMLSelectElement> | undefined
  onFocus: FocusEventHandler<HTMLSelectElement> | undefined
  value?: string
}

const SelectAtom = forwardRef<HTMLSelectElement, SelectAtomProps>(
  (
    { children, className, disabled, onBlur, onChange, onFocus, value },
    ref
  ) => (
    <styles.Select
      className={className}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      ref={ref}
      value={value}
    >
      {children}
    </styles.Select>
  )
)

const styles = {
  Select: styled.select`
    background-color: transparent;
    border: 0;
    padding: 0;
  `,
}

export default SelectAtom
