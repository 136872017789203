import React, { FC, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { navigate } from "gatsby"
import styled from "styled-components"

import HeaderMolecule, {
  HeaderStates,
} from "@components/molecules/HeaderMolecule"
import MenuOrganism, { MenuStates } from "@components/organisms/MenuOrganism"

import { Colors, Layers } from "@constants"

import { useBackground } from "@hooks"

import { BackgroundColors } from "@types/Background"
import { SectionItem } from "@types/HomeSection"
import { HomeSectionNames } from "../templates/Home"

export interface NavigationOrganismProps {
  dictionary: { [key: string]: string }
  enableRSVP: boolean
  id: string
  sectionItems: SectionItem[]
}

const NavigationOrganism: FC<NavigationOrganismProps> = ({
  dictionary,
  enableRSVP,
  id,
  sectionItems,
}) => {
  const { backgroundColor: color } = useBackground()

  const [headerState, setHeaderState] = useState<HeaderStates>(
    HeaderStates.INITIAL
  )
  const [menuState, setMenuState] = useState<MenuStates>(MenuStates.INITIAL)

  const languageClickHandler = () => navigate(`/${id === "en" ? "nl" : "en"}`)
  const menuButtonClickHandler = () => setMenuState(MenuStates.ANIMATE_IN)
  const menuCloseButtonClickHandler = () => setMenuState(MenuStates.ANIMATE_OUT)
  const rsvpButtonClickHandler = () =>
    (window.location.hash = HomeSectionNames.RSVP)

  useEffect(() => {
    setHeaderState(HeaderStates.ANIMATE_IN)
  }, [])

  return (
    <styles.Container>
      <HeaderMolecule
        animate={headerState}
        color={color === BackgroundColors.WHITE ? Colors.BLACK : Colors.WHITE}
        disabled={menuState === MenuStates.ANIMATE_IN}
        dictionary={dictionary}
        enableRSVP={enableRSVP}
        onLanguageClick={languageClickHandler}
        onMenuButtonClick={menuButtonClickHandler}
        onRSVPButtonClick={rsvpButtonClickHandler}
        slug={id}
      />
      <MenuOrganism
        animate={menuState}
        dictionary={dictionary}
        enableRSVP={enableRSVP}
        sectionItems={sectionItems}
        onCloseButtonClick={menuCloseButtonClickHandler}
      />
    </styles.Container>
  )
}

const styles = {
  Container: styled.header`
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
    z-index: ${Layers.NAVIGATION};
    -webkit-backface-visibility: hidden;
  `,
  Background: styled(motion.div)<{ $isEnabled: boolean }>`
    background-color: ${Colors.WHITE};
    bottom: 0;
    left: 0;
    position: absolute;
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
    right: 0;
    top: 0;
    will-change: opacity;
  `,
}

export default NavigationOrganism
