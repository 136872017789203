const React = require("react")

/**
 * Customise your Gatsby page here with background, custom providers, navigation and preloader
 */

const BackgroundContext =
  require("./src/contexts/BackgroundContext").BackgroundProvider
const ApplicationOrganism =
  require("./src/common/components/organisms/ApplicationOrganism").default
const BackgroundOrganism =
  require("./src/components/organisms/BackgroundOrganism").default
const NavigationOrganism =
  require("./src/components/organisms/NavigationOrganism").default
const PreloaderOrganism =
  require("./src/components/organisms/PreloaderOrganism").default
const RotateDeviceMolecule =
  require("./src/components/molecules/RotateDeviceMolecule").default

exports.shouldUpdateScroll = () => {
  return false
}

exports.wrapPageElement = ({ element, props }) => (
  <>
    <ApplicationOrganism
      background={<BackgroundOrganism assets={props?.pageContext?.assets} />}
      navigation={
        <NavigationOrganism
          dictionary={props?.pageContext?.dictionary}
          enableRSVP={props?.pageContext?.enableRSVP}
          id={props?.pageContext?.id}
          sectionItems={props?.pageContext?.sectionItems ?? []}
        />
      }
      preloaderComponent={PreloaderOrganism}
      providerComponent={BackgroundContext}
      {...props}
    >
      {element}
    </ApplicationOrganism>
    <RotateDeviceMolecule
      heading={props?.pageContext?.dictionary?.rotateDeviceWarning}
    />
  </>
)
