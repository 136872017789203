import React, { forwardRef, ReactNode } from "react"
import styled from "styled-components"

export interface LinkAtomProps {
  children: ReactNode
  className?: string
  href: string
  target: string
}

const LinkAtom = forwardRef<HTMLAnchorElement, LinkAtomProps>(
  ({ children, className, href, target, ...props }, ref) => (
    <styles.Link
      className={className}
      href={href}
      ref={ref}
      target={target}
      {...props}
    >
      {children}
    </styles.Link>
  )
)

const styles = {
  Link: styled.a`
    cursor: pointer;
    text-decoration: none;
    outline: none;
  `,
}

export default LinkAtom
