import { rem } from "@common/utils"

export const BreakpointUnits = {
  XL_MOBILE: 480,
  TABLET: 768,
  DESKTOP: 1024,
  XL_DESKTOP: 1440,
}

export const MediaQueries = {
  XL_MOBILE: `@media (min-width: ${rem(BreakpointUnits.XL_MOBILE)})`,
  XL_MOBILE_MAX: `@media (max-width: ${rem(BreakpointUnits.XL_MOBILE)})`,
  TABLET: `@media (min-width: ${rem(BreakpointUnits.TABLET)})`,
  DESKTOP: `@media (min-width: ${rem(BreakpointUnits.DESKTOP)})`,
  XL_DESKTOP: `@media (min-width: ${rem(BreakpointUnits.XL_DESKTOP)})`,
}
