import React, { FC, useRef } from "react"

import { AnimateStates } from "@common/constants"
import usePrevious from "@common/hooks/usePrevious"

import { SectionMolecule } from "@components/molecules"
import { RSVPFormMoleculeProps } from "@components/molecules/RSVPFormMolecule"
import {
  SectionHorizontalAlignments,
  SectionSizes,
  SectionVerticalAlignments,
} from "@components/molecules/SectionMolecule"
import { RSVPFormOrganism } from "@components/organisms"

import { HomeSection } from "@types/HomeSection"

export interface HomeRSVPOrganismProps {
  dictionary: { [key: string]: string }
}

const HomeRSVPOrganism: FC<
  HomeRSVPOrganismProps & RSVPFormMoleculeProps & HomeSection
> = ({ animate, ...props }) => {
  const previousAnimate = usePrevious(animate)
  const key = useRef<number>(0)

  // Reset form if navigating from new section
  if (
    previousAnimate !== animate &&
    previousAnimate !== AnimateStates.ANIMATE_IN &&
    animate === AnimateStates.ANIMATE_IN
  ) {
    key.current++
  }

  return (
    <SectionMolecule
      animate={animate}
      desktopAlignHorizontal={SectionHorizontalAlignments.RIGHT}
      desktopAlignVertical={SectionVerticalAlignments.BOTTOM}
      key={key.current}
      size={SectionSizes.FULL_WIDTH}
    >
      <RSVPFormOrganism
        animate={animate}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
        {...props}
      />
    </SectionMolecule>
  )
}

export default HomeRSVPOrganism
