import React, { FC, RefObject, useRef } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import { AnimateStates, BreakpointUnits } from "@common/constants"
import { useKey, useStepper, useSwipe } from "@common/hooks"
import { KeyArrows } from "@common/hooks/useKey"
import { SwipeDirection } from "@common/hooks/useSwipe"
import { AnimatedComponent } from "@common/types"
import { matchToLower, rem } from "@common/utils"

import { PaginationMolecule } from "@components/molecules"

export interface SliderMoleculeProps {
  className?: string
  doubleOnDesktop?: boolean
  direction?: number
  items?: any[]
  slideComponent: FC
}

const getAnimateProps = (
  animate: AnimateStates,
  step: number,
  previousStep: number,
  index: number
) => {
  if (animate === AnimateStates.ANIMATE_IN) {
    if (index === previousStep) {
      return {
        animate: AnimateStates.ANIMATE_OUT,
        delay: 0,
      }
    } else if (index === step) {
      return {
        animate: AnimateStates.ANIMATE_IN,
        delay: 0.4,
      }
    } else {
      return {
        animate: AnimateStates.INITIAL,
        delay: 0,
      }
    }
  } else if (animate === AnimateStates.ANIMATE_OUT) {
    return {
      animate:
        index === step ? AnimateStates.ANIMATE_OUT : AnimateStates.INITIAL,
      delay: 0,
    }
  }

  return {
    animate,
    delay: animate === AnimateStates.ANIMATE_IN ? 0.4 : 0,
  }
}

const SliderMolecule: FC<SliderMoleculeProps & AnimatedComponent> = ({
  animate,
  className,
  doubleOnDesktop = false,
  direction,
  items,
  slideComponent: SlideComponent,
}) => {
  const isTransitioning = useRef<boolean>(false)
  const timeout: RefObject<NodeJS.Timeout | null> = useRef(null)

  const showAsTwo =
    doubleOnDesktop &&
    typeof window === "object" &&
    window.innerWidth >= BreakpointUnits.DESKTOP
  const isEnabled = animate === AnimateStates.ANIMATE_IN
  const numItems = showAsTwo ? Math.ceil(items?.length / 2) : items?.length
  const slideWidth = 100 / numItems

  const { goBack, goForward, previousStep, step } = useStepper(0, numItems)

  const navigate = (direction: number) => {
    if (isTransitioning.current) return
    isTransitioning.current = true

    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    if (direction === -1) {
      goBack()
    } else if (direction === 1) {
      goForward()
    }

    setTimeout(() => {
      isTransitioning.current = false
    }, 1000)
  }

  useKey((key: string) => {
    if (matchToLower(KeyArrows.LEFT, key)) navigate(-1)
    else if (matchToLower(KeyArrows.RIGHT, key)) navigate(1)
  }, isEnabled)

  useSwipe((direction: SwipeDirection) => {
    if (direction === SwipeDirection.LEFT) navigate(1)
    else if (direction === SwipeDirection.RIGHT) navigate(-1)
  }, animate === AnimateStates.ANIMATE_IN && !isTransitioning.current)

  return (
    <styles.Container className={className}>
      <styles.SlideContainer
        $activeSlide={step}
        $numSlides={numItems}
        animate={{
          x: `${step * -slideWidth}%`,
        }}
        transition={{
          duration: 0,
          delay: 0.4,
        }}
      >
        {items?.map((item: any, index: number) => (
          <SlideComponent
            key={`${item?.heading}-${index}`}
            direction={direction}
            {...item}
            {...getAnimateProps(
              animate,
              step,
              previousStep,
              showAsTwo ? Math.floor(index / 2) : index
            )}
          />
        ))}
      </styles.SlideContainer>
      <styles.Pagination
        animate={animate}
        currentStep={step + 1}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.8 : 0}
        direction={direction}
        isFirst={step === 0}
        isLast={step === numItems - 1}
        numSteps={numItems}
        onNext={() => navigate(1)}
        onPrevious={() => navigate(-1)}
      />
    </styles.Container>
  )
}

const styles = {
  Container: styled.div`
    position: relative;
  `,
  SlideContainer: styled(motion.div)<{
    $activeSlide: number
    $numSlides: number
  }>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: ${rem(24)};
    position: relative;
    pointer-events: none;
    width: ${({ $numSlides }) => $numSlides * 100}%;
  `,
  Pagination: styled(PaginationMolecule)`
    left: 0;
    z-index: 3;
  `,
}

export default SliderMolecule
