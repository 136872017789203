import React, { FC } from "react"
import { Variants, motion } from "framer-motion"
import styled from "styled-components"

import {
  AnimatedSplitTextAtom,
  ButtonAtom,
  TextAtom,
} from "@common/components/atoms"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils/UnitUtils"

import { Colors, Easings } from "@constants"

import { BackgroundColors } from "@types/Background"

export const variants = {
  [AnimateStates.INITIAL]: ({ color }): unknown => ({
    pathLength: 0,
    pathOffset: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({
    delay = 0,
    duration = 0.4,
    easing = Easings.EaseOut,
  }): unknown => ({
    pathLength: 1,
    pathOffset: 0,
    transition: {
      delay,
      duration,
      ease: easing,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({
    delay = 0,
    duration = 0.4,
    easing = Easings.EaseOut,
  }) => ({
    pathLength: 0,
    pathOffset: 1,
    transition: {
      delay,
      duration,
      ease: easing,
    },
  }),
} as Variants

export interface CloseButtonMoleculeProps {
  className?: string
  color?: BackgroundColors
  delay: number
  disabled: boolean
  onClick: () => void
  text: string
}

const CloseButtonMolecule: FC<
  CloseButtonMoleculeProps & AnimatedStateComponent
> = ({
  animate,
  color = BackgroundColors.WHITE,
  className,
  delay = 0,
  disabled,
  onClick,
  text,
}) => (
  <styles.Button className={className} disabled={disabled} onClick={onClick}>
    <styles.Icon width={42} height={24} viewBox="0 0 42 24">
      <styles.Line1
        d="M1,1 40,23"
        animate={animate}
        custom={{ delay }}
        initial={AnimateStates.INITIAL}
        stroke={color}
        strokeWidth={rem(2)}
        variants={variants}
      />
      <styles.Line2
        d="M1,23 40,1"
        animate={animate}
        custom={{ delay: delay + 0.1 }}
        initial={AnimateStates.INITIAL}
        stroke={color}
        strokeWidth={rem(2)}
        variants={variants}
      />
    </styles.Icon>
    <styles.Label $color={color}>
      <AnimatedSplitTextAtom animate={animate} delay={delay + 0.2}>
        {text}
      </AnimatedSplitTextAtom>
    </styles.Label>
  </styles.Button>
)

const styles = {
  Button: styled(ButtonAtom)`
    border: 0;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    height: ${rem(60)};
    margin: auto;
    pointer-events: auto;
    width: ${rem(60)};

    &[disabled] {
      pointer-events: none;
    }

    ${MediaQueries.TABLET} {
      align-items: center;
      width: auto;
    }
  `,
  Icon: styled.svg`
    display: block;
    height: ${rem(24)};
    margin: auto;
    position: relative;
    width: ${rem(42)};
  `,
  Line1: motion.path,
  Line2: motion.path,
  Label: styled(TextAtom)<{ $color: Colors }>`
    display: none;

    ${MediaQueries.TABLET} {
      color: ${({ $color = Colors.WHITE }) => $color};
      display: flex;
      margin: auto ${rem(10)} auto ${rem(18)};
      position: relative;
      top: ${rem(1)};
    }
  `,
}

export default CloseButtonMolecule
