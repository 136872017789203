import React, { FC, FormEvent, useRef, useState } from "react"
import { Variants, motion } from "framer-motion"
import { useField } from "formik"
import styled from "styled-components"

import {
  AnimatedSplitTextAtom,
  FormFieldAtom,
  InputAtom,
  LabelAtom,
} from "@common/components/atoms"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedComponent } from "@common/types"
import { rem } from "@common/utils"

import { ErrorIconAtom } from "@components/atoms"
import { BorderMolecule } from "@components/molecules"

import { Colors, Easings, Typography } from "@constants"

import useShake from "@hooks/useShake"

import { ERROR_MESSAGE } from "@utils/schemas/RSVPSchema"

export interface FormTextFieldMoleculeProps {
  label: string
  keyClick: number
  name: string
}

const variants = {
  [AnimateStates.INITIAL]: {
    opacity: 0,
  },
  [AnimateStates.ANIMATE_IN]: ({ delay }: { delay: number }) => ({
    opacity: 1,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay }: { delay: number }) => ({
    opacity: 0,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
} as Variants

const FormTextFieldMolecule: FC<
  FormTextFieldMoleculeProps & AnimatedComponent
> = ({ animate, delay, keyClick, label, name }) => {
  const ref = useRef<FormFieldAtom>(null)

  const [field, meta, helpers] = useField({ name })
  const { value } = field
  const { error } = meta
  const { setError, setValue } = helpers

  const [focused, setFocused] = useState<boolean>(false)

  const hasError = error === ERROR_MESSAGE,
    labelAnimate =
      animate === AnimateStates.ANIMATE_IN
        ? focused || value !== ""
          ? AnimateStates.ANIMATE_OUT
          : animate
        : animate,
    labelDelay =
      animate === AnimateStates.ANIMATE_IN
        ? focused || value !== ""
          ? 0
          : delay
        : delay,
    labelDuration = 0.4,
    color = hasError ? Colors.ERROR : Colors.BLACK

  useShake(ref, keyClick, hasError)

  return (
    <>
      <styles.Field $isEnabled={animate === AnimateStates.ANIMATE_IN} ref={ref}>
        <styles.Border
          initial={AnimateStates.INITIAL}
          animate={animate}
          borderColor={color}
          delay={delay}
          ref={ref}
        />
        <styles.Label
          initial={AnimateStates.INITIAL}
          animate={{ color }}
          transition={{
            duration: 0.3,
            delay: 0,
          }}
        >
          <AnimatedSplitTextAtom
            initial={AnimateStates.INITIAL}
            animate={labelAnimate}
            delay={labelDelay}
            direction={1}
            duration={labelDuration}
            step={0.03}
          >
            {label}
          </AnimatedSplitTextAtom>
        </styles.Label>
        <styles.Input
          animate={animate}
          custom={{ delay }}
          initial={{ opacity: 0 }}
          onBlur={() => setFocused(false)}
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setValue(event.currentTarget.value)
          }
          onFocus={() => {
            setFocused(true)
            setError(undefined)
          }}
          type="text"
          value={value}
          variants={variants}
        />
        <styles.ErrorIcon
          $hasError={hasError && animate === AnimateStates.ANIMATE_IN}
          color={Colors.ERROR}
        />
      </styles.Field>
    </>
  )
}

const styles = {
  Field: styled(FormFieldAtom)<{ $isEnabled: boolean }>`
    margin-bottom: ${rem(16)};
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
    width: 100%;

    ${MediaQueries.DESKTOP} {
      margin-bottom: ${rem(20)};
    }
  `,
  Border: BorderMolecule,
  Label: styled(motion(LabelAtom))`
    left: ${rem(12)};
    pointer-events: none;
    position: absolute;
    top: ${rem(15.5)};

    ${MediaQueries.DESKTOP} {
      left: ${rem(22)};
      top: ${rem(20)};
    }
  `,
  Input: styled(motion(InputAtom))<HTMLInputElement>`
    cursor: pointer;
    display: flex;
    padding: ${rem(15.5)} ${rem(12)} ${rem(12.5)};
    width: inherit;

    outline: none;

    font-family: ${Typography.FONT_FAMILY_BODY};
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_LABEL)};
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_LABEL)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_LABEL)};

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_LABEL)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_LABEL)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_LABEL)};
    }

    ${MediaQueries.DESKTOP} {
      padding: ${rem(19)} ${rem(12)} ${rem(18)} ${rem(22)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_LABEL)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_LABEL)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_LABEL)};
    }
  `,
  ErrorIcon: styled(ErrorIconAtom)<{ $hasError: boolean }>`
    opacity: ${({ $hasError }) => ($hasError ? 1 : 0)};
    height: ${rem(24)};
    position: absolute;
    right: ${rem(12)};
    pointer-events: none;
    top: ${rem(12)};
    transition: opacity ${Easings.CssEaseInOutStrong} 300ms;
    width: ${rem(24)};

    ${MediaQueries.DESKTOP} {
      top: ${rem(16)};
    }
  `,
}

export default FormTextFieldMolecule
