import React, { FC } from "react"
import { Variants, motion } from "framer-motion"
import styled from "styled-components"

import {
  AnimatedSplitTextAtom,
  ButtonAtom,
  TextAtom,
} from "@common/components/atoms"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import usePrevious from "@common/hooks/usePrevious"
import { rem } from "@common/utils/UnitUtils"

import { Colors, Easings } from "@constants"

import { BackgroundColors } from "@types/Background"

export const variants = {
  [AnimateStates.INITIAL]: ({ color }): unknown => ({
    backgroundColor: color,
    originX: 0,
    scale: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({
    color,
    delay = 0,
    duration = 0.4,
    easing = Easings.EaseOut,
  }): unknown => ({
    backgroundColor: color,
    originX: 0,
    scale: 1,
    transition: {
      delay,
      duration,
      ease: easing,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({
    color,
    delay = 0,
    duration = 0.4,
    easing = Easings.EaseOut,
  }) => ({
    backgroundColor: color,
    originX: 0,
    scale: 0,
    transition: {
      delay,
      duration,
      ease: easing,
    },
  }),
} as Variants

export interface MenuButtonMoleculeProps {
  className?: string
  color?: BackgroundColors
  delay: number
  disabled: boolean
  onClick: () => void
  text: string
}

const MenuButtonMolecule: FC<
  MenuButtonMoleculeProps & AnimatedStateComponent
> = ({
  animate,
  color = BackgroundColors.WHITE,
  className,
  delay = 0,
  disabled = false,
  onClick,
  text,
}) => {
  const previous = usePrevious(color),
    step = previous && previous !== color ? 0 : 0.1

  return (
    <styles.Button className={className} disabled={disabled} onClick={onClick}>
      <styles.Icon>
        <styles.Line1
          animate={animate}
          custom={{ color, delay: delay + step * 0 }}
          variants={variants}
        />
        <styles.Line2
          animate={animate}
          custom={{ color, delay: delay + step * 1 }}
          variants={variants}
        />
        <styles.Line3
          animate={animate}
          custom={{ color, delay: delay + step * 2 }}
          variants={variants}
        />
      </styles.Icon>
      <styles.Label
        initial={{ color }}
        animate={{
          color,
        }}
        transition={{
          duration: 0.4,
          ease: Easings.EaseInOutStrong,
        }}
      >
        <AnimatedSplitTextAtom
          $color={color}
          animate={animate}
          delay={delay + 0.2}
        >
          {text}
        </AnimatedSplitTextAtom>
      </styles.Label>
    </styles.Button>
  )
}

const styles = {
  Button: styled(ButtonAtom)`
    border: 0;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    height: ${rem(60)};
    margin: auto;
    pointer-events: auto;
    width: ${rem(60)};

    &[disabled] {
      pointer-events: none;
    }

    ${MediaQueries.TABLET} {
      align-items: center;
      width: auto;
    }
  `,
  Icon: styled.span`
    display: block;
    height: ${rem(40)};
    margin: auto;
    position: relative;
    width: ${rem(40)};
  `,
  Line1: styled(motion.span)`
    height: ${rem(2)};
    left: 0;
    position: absolute;
    top: ${rem(10)};
    width: 100%;
  `,
  Line2: styled(motion.span)`
    height: ${rem(2)};
    left: 0;
    position: absolute;
    top: ${rem(20)};
    width: 100%;
  `,
  Line3: styled(motion.span)`
    height: ${rem(2)};
    left: 0;
    position: absolute;
    top: ${rem(30)};
    width: 100%;
  `,
  Label: styled(motion(TextAtom))<{ $color: Colors }>`
    display: none;

    ${MediaQueries.TABLET} {
      color: ${({ $color = Colors.WHITE }) => $color};
      display: flex;
      margin: auto ${rem(10)} auto ${rem(18)};
      position: relative;
      top: ${rem(1)};
    }
  `,
}

export default MenuButtonMolecule
