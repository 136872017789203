import React, { FC } from "react"
import styled from "styled-components"

export interface ArrowLeftAtomProps {
  className?: string
}

const ArrowLeftAtom: FC<ArrowLeftAtomProps> = ({ className }) => (
  <styles.Svg className={className} width="41" height="19" viewBox="0 0 41 19">
    <polyline
      fill="none"
      stroke="#FFF"
      strokeLinejoin="bevel"
      strokeWidth="2"
      points="0 9 40 9 30 0 40 9 30 17.092"
      transform="matrix(-1 0 0 1 41 1)"
    />
  </styles.Svg>
)

const styles = {
  Svg: styled.svg``,
}

export default ArrowLeftAtom
