import React, { FC } from "react"
import styled from "styled-components"

import AnimatedHeadingMolecule from "@common/components/molecules/AnimatedHeadingMolecule"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { ContainerAtom } from "@components/atoms"
import { ArtworkLinkMolecule, ScrollTipMolecule } from "@components/molecules"

import { Colors } from "@constants"

import { HomeSection } from "@types/HomeSection"

import { isTouch } from "@utils/MediaQueryUtils"

export interface HomeHeroOrganismProps {
  artworkInfo: string
  artworkInfoURL: string
  date: string
  heading: string
  location: string
  scrollTip: string
  swipeTip: string
}

const HomeHeroOrganism: FC<
  HomeHeroOrganismProps & HomeSection & AnimatedStateComponent
> = ({
  animate,
  artworkInfo: artworkInfoText,
  artworkInfoURL,
  date,
  heading,
  location,
  scrollTip,
  swipeTip,
}) => (
  <styles.Container $isEnabled={animate === AnimateStates.ANIMATE_IN}>
    <styles.TextContainer>
      <styles.Heading
        animate={animate}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
        size="h1"
      >
        {" " + heading}
      </styles.Heading>
      <styles.SubTextContainer>
        <styles.Heading
          animate={animate}
          delay={animate === AnimateStates.ANIMATE_IN ? 0.5 : 0.05}
          size="h4"
        >
          {date}
        </styles.Heading>
        <styles.LocationText
          animate={animate}
          delay={animate === AnimateStates.ANIMATE_IN ? 0.6 : 0.1}
          size="h5"
        >
          {location}
        </styles.LocationText>
      </styles.SubTextContainer>
    </styles.TextContainer>
    <styles.ArtworkLink
      animate={animate}
      delay={animate === AnimateStates.ANIMATE_IN ? 0.65 : 0.15}
      href={artworkInfoURL}
      text={artworkInfoText}
    />
    <styles.ScrollTip
      animate={animate}
      delay={animate === AnimateStates.ANIMATE_IN ? 0.7 : 0.15}
    >
      {isTouch() ? swipeTip : scrollTip}
    </styles.ScrollTip>
  </styles.Container>
)

const styles = {
  Container: styled(ContainerAtom)<{ $isEnabled: boolean }>`
    align-items: center;
    height: var(--viewportHeight);
    justify-content: center;
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
  `,
  TextContainer: styled.div`
    margin: auto;
    position: relative;

    ${MediaQueries.XL_DESKTOP} {
      padding-bottom: ${rem(84)};
    }
  `,
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    margin: 0 auto;
    text-align: center;
  `,
  SubTextContainer: styled.div`
    display: flex;
    flex-direction: column;
    left: 0;
    margin-top: ${rem(8)};
    position: absolute;
    right: 0;

    ${MediaQueries.XL_DESKTOP} {
      margin-top: ${rem(4)};
    }
  `,
  LocationText: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    margin: ${rem(8)} auto 0;

    ${MediaQueries.XL_DESKTOP} {
      margin: ${rem(4)} auto 0;
    }
  `,
  ArtworkLink: styled(ArtworkLinkMolecule)`
    display: none;

    ${MediaQueries.TABLET} {
      bottom: ${rem(40)};
      display: flex;
      left: ${rem(44)};
      position: absolute;
    }

    ${MediaQueries.XL_DESKTOP} {
      bottom: ${rem(44)};
      left: ${rem(46)};
    }
  `,
  ScrollTip: styled(ScrollTipMolecule)`
    bottom: ${rem(28)};
    color: ${Colors.WHITE};
    display: block;
    left: ${rem(32)};
    position: absolute;
    right: auto;

    ${MediaQueries.TABLET} {
      bottom: ${rem(36)};
      left: auto;
      right: ${rem(44)};
    }

    ${MediaQueries.XL_DESKTOP} {
      bottom: ${rem(44)};
      right: ${rem(50)};
    }
  `,
}

export default HomeHeroOrganism
