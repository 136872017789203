import React, { FC, useMemo } from "react"
import { motion, Variants } from "framer-motion"
import styled from "styled-components"

import { AnimateStates } from "@common/constants"
import { AnimatedComponent } from "@common/types"

import { Easings } from "@constants"

export interface AnimatedSplitTextAtomProps extends AnimatedComponent {}

export const variants = {
  [AnimateStates.INITIAL]: ({ direction = 1 }) => ({
    y: `${direction * 110}%`,
    transition: {
      duration: 0,
      delay: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({
    delay = 0,
    direction = 1,
    duration = 0.4,
    easing = Easings.EaseInOutStrong,
    i = 0,
    step = 0.015,
  }) => ({
    y: [`${direction * 110}%`, `${direction * 110}%`, "0%"],
    transition: {
      delay: delay + i * step,
      duration,
      ease: easing,
      times: [0, 0, 1],
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({
    delay = 0,
    direction = 1,
    duration = 0.4,
    easing = Easings.EaseOut,
    i = 0,
    step = 0.015,
  }) => ({
    y: `${-direction * 110}%`,
    transition: {
      delay: delay + i * step,
      duration,
      ease: easing,
    },
  }),
} as Variants

const AnimatedSplitTextAtom: FC<AnimatedComponent> = ({
  animate = AnimateStates.INITIAL,
  children,
  className,
  delay = 0,
  duration = 0.4,
  direction = 1,
  easing = Easings.EaseInOutStrong,
  onAnimationComplete,
  step = 0.015,
}) => {
  const words = useMemo(
    () =>
      (children &&
        children
          .split(" ")
          .filter((value: string) => value !== "")
          .map((item: string) => {
            let isItalic = false
            let word = item

            if (word.charAt(0) === "*") {
              word = word.substring(1)
              isItalic = true
            }
            if (word.charAt(word.length - 1) === "*") {
              word = word.substring(0, word.length - 1)
              isItalic = true
            }

            return {
              isItalic,
              word,
            }
          })) ??
      [],
    [children]
  )

  const length = words.length - 1

  return words.map(
    ({ word, isItalic }: { word: string; isItalic: boolean }, i: number) => (
      <styles.Mask key={children + i}>
        <styles.Word
          $isItalic={isItalic}
          animate={animate}
          className={className}
          custom={{
            delay,
            direction,
            duration,
            easing,
            i,
            step,
          }}
          initial={AnimateStates.INITIAL}
          onAnimationComplete={
            i === length && onAnimationComplete
              ? () => {
                  onAnimationComplete()
                }
              : undefined
          }
          variants={variants}
        >
          {word + "\u00A0"}
        </styles.Word>
      </styles.Mask>
    )
  )
}

const styles = {
  Mask: styled.span`
    display: inline-block;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
  `,
  Word: styled(motion.span)<{ $isItalic: boolean }>`
    display: inline-block;
    will-change: transform;
    font-style: ${({ $isItalic }: { $isItalic: boolean }) =>
      $isItalic ? "italic" : "normal"};
    -webkit-backface-visibility: hidden;
  `,
}

export default AnimatedSplitTextAtom
