import React, { FC } from "react"
import styled from "styled-components"

import { AnimatedHeadingMolecule } from "@common/components/molecules"
import { AnimateStates, BreakpointUnits } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"

import { SectionMolecule } from "@components/molecules"
import {
  SectionHorizontalAlignments,
  SectionVerticalAlignments,
  SectionSizes,
} from "@components/molecules/SectionMolecule"

import { Colors } from "@constants"

import { HomeSection } from "@types/HomeSection"

export interface HomeIntroductionOrganismProps {
  heading: string
}

const HomeIntroductionOrganism: FC<
  HomeIntroductionOrganismProps & HomeSection & AnimatedStateComponent
> = ({ animate, direction, heading }) => (
  <SectionMolecule
    animate={animate}
    desktopAlignHorizontal={SectionHorizontalAlignments.RIGHT}
    mobileAlignVertical={SectionVerticalAlignments.BOTTOM}
    size={SectionSizes.TWO_THREE_WIDTH}
  >
    <styles.Heading
      animate={animate}
      delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
      direction={direction}
      size="h2"
      step={0.01}
    >
      {heading}
    </styles.Heading>
  </SectionMolecule>
)

const styles = {
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    flex-grow: 0;
    flex-shrink: 0;

    ${BreakpointUnits.XL_DESKTOP} {
      border: 1px solid red;
    }
  `,
}

export default HomeIntroductionOrganism
