import React, { FC, ReactNode } from "react"
import styled from "styled-components"

import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { ContainerAtom } from "@components/atoms"

export enum SectionHorizontalAlignments {
  CENTER = "center",
  LEFT = "flex-start",
  RIGHT = "flex-end",
}

export enum SectionVerticalAlignments {
  BOTTOM = "flex-end",
  CENTER = "center",
  TOP = "flex-start",
}

export enum SectionSizes {
  FULL_WIDTH = "full",
  TWO_THREE_WIDTH = "two-three-width",
  HALF_WIDTH = "half",
}

export interface SectionMoleculeProps {
  animate: AnimateStates
  children: ReactNode
  delay: number
  desktopAlignHorizontal?: SectionHorizontalAlignments
  desktopAlignVertical?: SectionVerticalAlignments
  heading?: string
  headingSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "intro"
  mobileAlignHorizontal?: SectionHorizontalAlignments
  mobileAlignVertical?: SectionVerticalAlignments
  size?: SectionSizes
}

const TabletSizes = {
  [SectionSizes.FULL_WIDTH]: "none",
  [SectionSizes.TWO_THREE_WIDTH]: "58vw",
  [SectionSizes.HALF_WIDTH]: "34vw",
}

const DesktopSizes = {
  [SectionSizes.FULL_WIDTH]: "none",
  [SectionSizes.TWO_THREE_WIDTH]: "58vw",
  [SectionSizes.HALF_WIDTH]: "34vw",
}

const XLDesktopSizes = {
  [SectionSizes.FULL_WIDTH]: "none",
  [SectionSizes.TWO_THREE_WIDTH]: "58vw",
  [SectionSizes.HALF_WIDTH]: "34vw",
}

const SectionMolecule: FC<SectionMoleculeProps & AnimatedStateComponent> = ({
  animate,
  children,
  desktopAlignHorizontal = SectionHorizontalAlignments.LEFT,
  desktopAlignVertical = SectionVerticalAlignments.BOTTOM,
  mobileAlignHorizontal = SectionHorizontalAlignments.LEFT,
  mobileAlignVertical = SectionVerticalAlignments.BOTTOM,
  size = SectionSizes.HALF_WIDTH,
}) => (
  <styles.Container
    $desktopAlignmentHorizontal={desktopAlignHorizontal}
    $desktopAlignmentVertical={desktopAlignVertical}
    $isEnabled={animate === AnimateStates.ANIMATE_IN}
    $mobileAlignmentHorizontal={mobileAlignHorizontal}
    $mobileAlignmentVertical={mobileAlignVertical}
  >
    <styles.Inner $size={size}>{children}</styles.Inner>
  </styles.Container>
)

const styles = {
  Container: styled(ContainerAtom)<{
    $desktopAlignmentHorizontal: SectionHorizontalAlignments
    $desktopAlignmentVertical: SectionVerticalAlignments
    $isEnabled: boolean
    $mobileAlignmentHorizontal: SectionHorizontalAlignments
    $mobileAlignmentVertical: SectionVerticalAlignments
  }>`
    align-items: ${({ $mobileAlignmentVertical }) => $mobileAlignmentVertical};
    height: calc(var(--viewportHeight) - ${rem(140)});
    justify-content: ${({ $mobileAlignmentHorizontal }) =>
      $mobileAlignmentHorizontal};
    padding: ${rem(104)} ${rem(36)} ${rem(36)};
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};

    ${MediaQueries.TABLET} {
      align-items: ${({ $desktopAlignmentVertical }) => $desktopAlignmentVertical};
      height: calc(var(--viewportHeight) - max(8vw, ${rem(132)}));
      justify-content: ${({ $desktopAlignmentHorizontal }) =>
        $desktopAlignmentHorizontal};
      padding: max(4vw, ${rem(68)}) max(8vw, ${rem(136)}) max(4vw, ${rem(68)})
        max(4vw, ${rem(68)});
    }

    ${MediaQueries.DESKTOP} {
      height: calc(var(--viewportHeight) - max(8vw, ${rem(136)}));
      padding: max(4vw, ${rem(68)}) max(8vw, ${rem(136)}) max(4vw, ${rem(68)})
        max(4vw, ${rem(68)});
    }
  `,
  Inner: styled.div<{
    $size: SectionSizes
  }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    ${MediaQueries.TABLET} {
      max-width: ${({ $size }) =>
        TabletSizes[$size] ?? TabletSizes[SectionSizes.FULL_WIDTH]};
    }

    ${MediaQueries.DESKTOP} {
      max-width: ${({ $size }) =>
        DesktopSizes[$size] ?? DesktopSizes[SectionSizes.FULL_WIDTH]};
    }

    ${MediaQueries.XL_DESKTOP} {
      max-width: ${({ $size }) =>
        XLDesktopSizes[$size] ?? XLDesktopSizes[SectionSizes.FULL_WIDTH]};
    }
  `,
}

export default SectionMolecule
