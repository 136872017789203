import { animate as FramerAnimate } from "framer-motion"
import * as THREE from "three"

import { Easings } from "@constants"

import { BackgroundFragmentShader, BackgroundVertexShader } from "@gfx/Shaders"

export interface WebGLBackground {
  animate: (
    texture: THREE.Texture,
    onComplete: () => void,
    duration: number
  ) => void
  init: (texture: THREE.Texture) => THREE.Mesh
  render: (time: number) => void
  resize: (
    width: number,
    height: number,
    scaleX: number,
    scaleY: number
  ) => void
}

const Background = (): WebGLBackground => {
  let _backgroundGeometry: THREE.PlaneGeometry,
    _backgroundMaterial: THREE.ShaderMaterial,
    _backgroundPlane: THREE.PlaneGeometry,
    _backgroundTexture: THREE.Texture

  // Animate texture from 0 to 1
  const animate = (
    texture: THREE.Texture,
    onComplete: () => void,
    duration: number = 1.5
  ) => {
    if (!texture || _backgroundTexture?.uuid === texture?.uuid) return

    _backgroundTexture = texture

    _backgroundMaterial.uniforms.texture2.value = _backgroundTexture

    FramerAnimate(0, 1, {
      duration,
      ease: Easings.EaseInOutStrong,
      onUpdate: value => (_backgroundMaterial.uniforms.progress.value = value),
      onComplete: () => {
        _backgroundMaterial.uniforms.texture1.value = _backgroundTexture
        _backgroundMaterial.uniforms.progress.value = 0

        onComplete()
      },
    })
  }

  // Initialize plane by creating mesh to add to scene
  const init = (texture: THREE.Texture): THREE.Mesh => {
    _backgroundMaterial = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        progress: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        texture1: { type: "f", value: texture },
        texture2: { type: "f", value: texture },
        time: { type: "f", value: 0 },
      },
      vertexShader: BackgroundVertexShader,
      fragmentShader: BackgroundFragmentShader,
    })

    _backgroundTexture = texture

    _backgroundGeometry = new THREE.PlaneGeometry(1, 1, 2, 2)

    _backgroundPlane = new THREE.Mesh(_backgroundGeometry, _backgroundMaterial)

    return _backgroundPlane
  }

  // Update based upon time
  const render = (time: number) => {
    _backgroundMaterial.uniforms.time.value = time
  }

  // Resize plane
  // TODO: Fix background position
  const resize = (
    width: number,
    height: number,
    scaleX: number,
    scaleY: number
  ) => {
    if (_backgroundTexture === undefined) return

    const imageAspect =
      _backgroundTexture.image.height / _backgroundTexture.image.width

    let a1
    let a2
    if (height / width > imageAspect) {
      a1 = (width / height) * imageAspect
      a2 = 1
    } else {
      a1 = 1
      a2 = height / width / imageAspect
    }

    _backgroundMaterial.uniforms.resolution.value.x = width
    _backgroundMaterial.uniforms.resolution.value.y = height
    _backgroundMaterial.uniforms.resolution.value.z = a1
    _backgroundMaterial.uniforms.resolution.value.w = a2

    _backgroundPlane.scale.set(scaleX, scaleY, 1)
  }

  return {
    animate,
    init,
    render,
    resize,
  }
}

export default Background
