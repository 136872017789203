import React, { FC, ReactNode, useMemo } from "react"
import styled from "styled-components"

import { MediaQueries } from "@common/constants"
import { rem } from "@common/utils"

import { Typography } from "@constants"

export interface RichTextAtomProps {
  children?: ReactNode
  className?: string
  size: "paragraph" | "intro"
  text: string
}

const RichTextAtom: FC<RichTextAtomProps> = ({
  children,
  className,
  size,
  text,
}) => {
  const Text = useMemo(() => styles[size] ?? styles.paragraph, [size])

  return children ? (
    <Text className={className}>{children}</Text>
  ) : (
    <Text className={className} dangerouslySetInnerHTML={{ __html: text }} />
  )
}

const styles = {
  paragraph: styled.div`
    font-family: ${Typography.FONT_FAMILY_BODY};
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_PARAGRAPH)};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_PARAGRAPH)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_PARAGRAPH)};
    margin: 0 0 ${rem(24)};

    p {
      margin: 0 0 ${rem(24)};
    }

    > :last-child {
      margin-bottom: 0;
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_PARAGRAPH)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_PARAGRAPH)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_PARAGRAPH)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_PARAGRAPH)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_PARAGRAPH)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_PARAGRAPH)};
    }
  `,
  intro: styled.div`
    font-family: ${Typography.FONT_FAMILY_BODY};
    font-size: ${rem(Typography.FONT_SIZE_MOBILE_INTRO)};
    letter-spacing: ${rem(Typography.LETTER_SPACING_MOBILE_INTRO)};
    line-height: ${rem(Typography.LINE_HEIGHT_MOBILE_INTRO)};
    margin: 0 0 ${rem(24)};

    p {
      margin: 0 0 ${rem(24)};
    }

    > :last-child {
      margin-bottom: 0;
    }

    ${MediaQueries.TABLET} {
      font-size: ${rem(Typography.FONT_SIZE_TABLET_INTRO)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_TABLET_INTRO)};
      line-height: ${rem(Typography.LINE_HEIGHT_TABLET_INTRO)};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${rem(Typography.FONT_SIZE_XL_DESKTOP_INTRO)};
      letter-spacing: ${rem(Typography.LETTER_SPACING_XL_DESKTOP_INTRO)};
      line-height: ${rem(Typography.LINE_HEIGHT_XL_DESKTOP_INTRO)};
    }
  `,
}

export default RichTextAtom
