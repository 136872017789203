import React, { forwardRef } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import { AnimateStates } from "@common/constants"
import { useResizeObserver } from "@common/hooks"
import { AnimatedStateComponent } from "@common/types"

import { Colors, Easings } from "@constants"

const round = Math.round

const variants = {
  [AnimateStates.INITIAL]: {
    pathLength: 0,
  },
  [AnimateStates.ANIMATE_IN]: ({ delay }: { delay: number }) => ({
    pathLength: 1,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay }: { delay: number }) => ({
    pathLength: 0,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
}

export interface BorderMoleculeProps {
  className?: string
  borderColor?: string
  borderWidth?: number
  delay: number
  ref?: any
}

const BorderMolecule = forwardRef<
  HTMLElement,
  BorderMoleculeProps & AnimatedStateComponent
>(
  (
    {
      animate,
      borderColor = Colors.WHITE,
      borderWidth = 1,
      className,
      delay = 0,
    },
    ref
  ) => {
    const { height: refHeight, width: refWidth } = useResizeObserver(ref)
    const height = refHeight ?? 0,
      width = round(refWidth ?? 0),
      halfWidth = round(borderWidth / 2)

    return (
      <styles.SVG
        className={className}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <styles.Border
          animate={animate}
          custom={{
            delay,
          }}
          d={`M${halfWidth},${halfWidth} ${width - borderWidth},${halfWidth} ${
            width - borderWidth
          },${height - borderWidth}`}
          fill="none"
          initial={{ pathLength: 0 }}
          shapeRendering="crispEdges"
          stroke={borderColor}
          strokeWidth={borderWidth}
          variants={variants}
        />
        <styles.Border
          animate={animate}
          custom={{
            delay,
          }}
          d={`M${halfWidth},${halfWidth} ${halfWidth},${height - borderWidth} ${
            width - borderWidth
          },${height - borderWidth}`}
          fill="none"
          initial={{ pathLength: 0 }}
          shapeRendering="crispEdges"
          stroke={borderColor}
          strokeWidth={borderWidth}
          variants={variants}
        />
      </styles.SVG>
    )
  }
)

const styles = {
  SVG: styled.svg`
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    pointer-events: none;
  `,
  Border: styled(motion.path)``,
}

export default BorderMolecule
