import { useEffect } from "react"

export enum KeyArrows {
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
  UP = "ArrowUp",
}

/**
 * Hook with callback to listen for keydown and return key value
 *
 * @param callback
 */
const useKey = (callback: (key: string) => void, isEnabled: boolean = true) => {
  const onKeyDown = (event: KeyboardEvent): void => {
    if (isEnabled && callback) {
      callback(event.key)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown)

    return (): void => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [])
}

export default useKey
