import React, { FC } from "react"
import styled from "styled-components"
import { motion, Variants } from "framer-motion"

import {
  AnimatedSplitTextAtom,
  ButtonAtom,
  LabelAtom,
} from "@common/components/atoms"
import { AnimateStates } from "@common/constants"
import { rem } from "@common/utils"

import { ArrowLeftAtom, ArrowRightAtom } from "@components/atoms"

import { Colors, Easings } from "@constants"

export const variants = {
  [AnimateStates.INITIAL]: (): unknown => ({
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({
    delay,
    disabled,
  }: {
    delay: number
    disabled: boolean
  }): unknown => ({
    opacity: disabled ? 0.4 : 1,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
      times: [0, 0, 1],
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay }: { delay: number }): unknown => ({
    opacity: 0,
    transition: {
      delay,
      duration: 0.3,
      ease: Easings.EaseInOutStrong,
    },
  }),
} as Variants

export interface PaginationMoleculeProps {
  animate: AnimateStates
  className?: string
  currentStep: number
  delay?: number
  isFirst?: boolean
  isLast?: boolean

  numSteps: number
  onNext: () => void
  onPrevious: () => void
}

const PaginationMolecule: FC<PaginationMoleculeProps> = ({
  animate,
  className,
  currentStep,
  delay = 0,
  isFirst = false,
  isLast = false,
  numSteps,
  onNext,
  onPrevious,
}) => (
  <styles.Pagination
    className={className}
    $isEnabled={animate === AnimateStates.ANIMATE_IN}
  >
    <styles.ButtonPrevious
      animate={animate}
      custom={{
        disabled: isFirst || animate !== AnimateStates.ANIMATE_IN,
        delay: animate === AnimateStates.ANIMATE_IN ? delay : 0,
      }}
      disabled={isFirst || animate !== AnimateStates.ANIMATE_IN}
      onClick={onPrevious}
      variants={variants}
    >
      <styles.ArrowLeft />
    </styles.ButtonPrevious>
    <styles.Counter>
      <AnimatedSplitTextAtom
        initial={AnimateStates.INITIAL}
        animate={animate}
        delay={animate === AnimateStates.ANIMATE_IN ? delay + 0.05 : 0}
      >
        {`${currentStep} / ${numSteps}`}
      </AnimatedSplitTextAtom>
    </styles.Counter>
    <styles.ButtonNext
      animate={animate}
      custom={{
        disabled: isLast || animate !== AnimateStates.ANIMATE_IN,
        delay: animate === AnimateStates.ANIMATE_IN ? delay + 0.1 : 0,
      }}
      disabled={isLast || animate !== AnimateStates.ANIMATE_IN}
      onClick={onNext}
      variants={variants}
    >
      <styles.ArrowRight />
    </styles.ButtonNext>
  </styles.Pagination>
)

const styles = {
  Pagination: styled.div<{ $isEnabled: boolean }>`
    display: flex;
    pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
  `,
  ButtonPrevious: motion(ButtonAtom),
  Counter: styled(LabelAtom)`
    color: ${Colors.WHITE};
    margin-bottom: auto;
    margin-top: auto;
    padding: 0 ${rem(12)};
    position: relative;
    top: ${rem(1)};
  `,
  ArrowLeft: styled(ArrowLeftAtom)`
    stroke: ${Colors.WHITE};
  `,
  ButtonNext: motion(ButtonAtom),
  ArrowRight: styled(ArrowRightAtom)`
    stroke: ${Colors.WHITE};
  `,
}

export default PaginationMolecule
