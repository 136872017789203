import React, { FC, useRef } from "react"
import { motion, Variants } from "framer-motion"
import styled from "styled-components"

import { AnimateStates } from "@common/constants"
import { useResizeObserver } from "@common/hooks"
import { AnimatedComponent } from "@common/types"

import { Easings } from "@constants"

const HIDDEN_Y = 250
const SCALE_Y = 1.5

export interface AnimatedMaskAtomProps {
  className?: string
}

export const variants = {
  [AnimateStates.INITIAL]: ({ direction = 1, height }): unknown => ({
    y: `${direction * (height ?? HIDDEN_Y)}px`,
    transition: {
      delay: 0,
      duration: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({
    delay = 0,
    direction = 1,
    duration = 0.4,
    easing = Easings.EaseOut,
    height,
  }): unknown => ({
    y: [direction * height * SCALE_Y, direction * height * SCALE_Y, 0],
    scaleY: [SCALE_Y, SCALE_Y, 1],
    transition: {
      delay,
      duration,
      ease: easing,
      times: [0, 0, 1],
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({
    delay = 0,
    direction = -1,
    duration = 0.4,
    easing = Easings.EaseOut,
    height,
  }) => ({
    y: [0, 0, direction * height * SCALE_Y],
    scaleY: [1, 1, SCALE_Y],
    transition: {
      delay,
      duration,
      ease: easing,
    },
  }),
} as Variants

const AnimatedMaskAtom: FC<AnimatedMaskAtomProps & AnimatedComponent> = ({
  animate = AnimateStates.INITIAL,
  children,
  className,
  delay = 0,
  duration = 0.4,
  direction,
}) => {
  const ref = useRef(null)

  const { height } = useResizeObserver(ref)

  const props = {
    animate,
    custom: {
      delay,
      direction,
      duration,
      height,
    },
    variants,
  }

  return (
    <styles.Mask>
      <styles.Container
        className={className}
        ref={ref}
        style={{ originY: "100%" }}
        {...props}
      >
        {children}
      </styles.Container>
    </styles.Mask>
  )
}

const styles = {
  Mask: styled.div`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    width: inherit;
  `,
  Container: styled(motion.div)`
    display: flex;
    line-height: inherit;
    font-size: inherit;
    width: inherit;
    will-change: transform;
  `,
}

export default AnimatedMaskAtom
