import React, { FC } from "react"
import styled from "styled-components"

export interface SlideNavigationMoleculeProps {}

const SlideNavigationMolecule: FC<SlideNavigationMoleculeProps> = () => (
  <styles.Container>ScrollTipButotnMolecule</styles.Container>
)

const styles = {
  Container: styled.div``,
}

export default SlideNavigationMolecule
