import React, { FC } from "react"
import styled from "styled-components"

import { MediaQueries } from "@common/constants"
import { rem } from "@common/utils/UnitUtils"

import MenuButtonMolecule from "@components/molecules/MenuButtonMolecule"
import HeaderButtonMolecule from "@components/molecules/HeaderButtonMolecule"

import { Colors } from "@constants"

export interface HeaderMoleculeProps {
  animate: HeaderStates
  dictionary: { [key: string]: string }
  disabled?: boolean
  color: Colors
  enableRSVP: boolean
  onLanguageClick: () => void
  onMenuButtonClick: () => void
  onRSVPButtonClick?: () => void
  slug: string
}

export enum HeaderStates {
  INITIAL = "initial",
  ANIMATE_IN = "animateIn",
  ANIMATE_OUT = "animateOut",
}

const HeaderMolecule: FC<HeaderMoleculeProps> = ({
  animate,
  color,
  dictionary,
  disabled = false,
  enableRSVP,
  onLanguageClick,
  onMenuButtonClick,
  onRSVPButtonClick,
  slug,
}) => (
  <>
    <styles.MenuButton
      animate={animate}
      color={color}
      disabled={disabled}
      onClick={onMenuButtonClick}
      text={dictionary?.menuButton}
    />
    <styles.Container>
      {enableRSVP && (
        <styles.HeaderButton
          animate={color === Colors.BLACK ? HeaderStates.ANIMATE_OUT : animate}
          color={color}
          delay={
            animate === HeaderStates.ANIMATE_IN && color === Colors.WHITE
              ? 0.2
              : 0
          }
          disabled={disabled}
          onClick={onRSVPButtonClick}
          text={dictionary?.rsvpButton}
        />
      )}
      <styles.HeaderButton
        animate={animate}
        color={color}
        delay={
          animate === HeaderStates.ANIMATE_IN && color === Colors.WHITE
            ? 0.2
            : 0
        }
        disabled={disabled}
        onClick={onLanguageClick}
        text={
          slug === "en"
            ? dictionary?.languageDutch
            : dictionary?.languageEnglish
        }
      />
    </styles.Container>
  </>
)

const styles = {
  MenuButton: styled(MenuButtonMolecule)`
    left: ${rem(26)};
    margin: 0;
    pointer-events: auto;
    position: absolute;
    top: ${rem(16)};

    ${MediaQueries.TABLET} {
      left: ${rem(46)};
      top: ${rem(26)};
    }
  `,
  Container: styled.div`
    display: flex;
    margin: 0;
    pointer-events: auto;
    position: absolute;
    right: ${rem(26)};
    top: ${rem(17)};

    ${MediaQueries.TABLET} {
      right: ${rem(46)};
      top: ${rem(26)};
    }
  `,
  HeaderButton: styled(HeaderButtonMolecule)`
    margin-left: ${rem(32)};

    &:first-child {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    ${MediaQueries.TABLET} {
      margin-left: ${rem(60)};
    }
  `,
}

export default HeaderMolecule
