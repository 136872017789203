import React, { FC } from "react"
import styled from "styled-components"

import { AnimatedSplitTextAtom, TextAtom } from "@common/components/atoms"
import { TextAtomProps } from "@common/components/atoms/TextAtom"
import { AnimatedSplitTextAtomProps } from "@common/components/atoms/AnimatedSplitTextAtom"

export interface ScrollTipMoleculeProps {
  className: string
}

const ScrollTipMolecule: FC<
  ScrollTipMoleculeProps & TextAtomProps & AnimatedSplitTextAtomProps
> = ({ animate, children, className, delay, direction, duration }) => (
  <styles.Text className={className}>
    <AnimatedSplitTextAtom
      animate={animate}
      delay={delay}
      direction={direction}
      duration={duration}
      step={0.03}
    >
      {children}
    </AnimatedSplitTextAtom>
  </styles.Text>
)

const styles = {
  Text: styled(TextAtom)``,
}

export default ScrollTipMolecule
