import React, { FC, ReactNode, useEffect } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import Orientations from "@common/constants/Orientations"

import useSmoothScroll from "@common/hooks/useSmoothScroll"

import { publish } from "@common/utils/MessengerUtils"

interface SpringScrollMoleculeProps {
  children: ReactNode
  enabled: boolean
  orientation: Orientations
}

const SpringScrollMolecule: FC<SpringScrollMoleculeProps> = ({
  children,
  enabled,
  orientation = Orientations.VERTICAL,
}) => {
  const { containerRef, scrollRef, value } = useSmoothScroll(orientation)

  useEffect(() => {
    let unsubscribe: () => void

    if (orientation === Orientations.VERTICAL) {
      unsubscribe = value.y.onChange((latest: number) =>
        enabled ? publish("scroll::change", latest) : null
      )
    }
    if (orientation === Orientations.HORIZONTAL) {
      unsubscribe = value.x.onChange((latest: number) =>
        enabled ? publish("scroll::change", latest) : null
      )
    }

    return () => {
      if (unsubscribe && typeof unsubscribe === "function") {
        unsubscribe()
      }
    }
  }, [enabled, orientation, value])

  return (
    <>
      <styles.Scroll ref={scrollRef} />
      <styles.Container
        id="container"
        ref={containerRef}
        orientation={orientation}
        style={{ ...value }}
      >
        {children}
      </styles.Container>
    </>
  )
}

const styles = {
  Container: styled(motion.div)`
    display: flex;
    flex-direction: ${({ orientation }: { orientation: Orientations }) =>
      orientation === Orientations.HORIZONTAL ? "row" : "column"};
    flex-grow: 0;
    flex-shrink: 0;
    flex-wrap: nowrap;
    height: ${({ orientation }: { orientation: Orientations }) =>
      orientation === Orientations.HORIZONTAL ? "var(--viewportHeight)" : "auto"};
    height: ${({ orientation }: { orientation: Orientations }) =>
      orientation === Orientations.HORIZONTAL
        ? "var(--viewportHeight)"
        : "auto"};
    left: 0;
    position: fixed;
    top: 0;
    transform-origin: 0% 0%;
    width: ${({ orientation }: { orientation: Orientations }) =>
      orientation === Orientations.HORIZONTAL ? "auto" : "inherit"}; ;
  `,
  Scroll: styled.div`
    display: block;
    width: 100%;
    height: 100000px;
    position: relative;
  `,
}

export default SpringScrollMolecule
