import React, { FC, useState } from "react"
import { Formik } from "formik"

import { AnimateStates, BreakpointUnits } from "@common/constants"
import { useViewportObserver } from "@common/hooks"
import { AnimatedStateComponent } from "@common/types"

import { RSVPHeaderMolecule, RSVPFormMolecule } from "@components/molecules"
import { RSVPHeaderStates } from "@components/molecules/RSVPHeaderMolecule"
import { HomeRSVPOrganismProps } from "@components/organisms/HomeRSVPOrganism"

import {
  initialValues,
  RSVPForm,
  RSVPAccommodationOptions,
  RSVPAttendingOptions,
  validationSchema,
} from "@utils/schemas/RSVPSchema"

export interface RSVPFormOrganismProps {}

const RSVPFormOrganism: FC<
  RSVPFormOrganismProps & HomeRSVPOrganismProps & AnimatedStateComponent
> = ({
  animate,
  bodyText,
  delay,
  heading,
  submittedBodyTextNo,
  submittedBodyTextYes,
  submittedHeadingNo,
  submittedHeadingYes,
  ...props
}) => {
  const { width } = useViewportObserver()

  const [headerState, setHeaderState] = useState<RSVPHeaderStates>(
    RSVPHeaderStates.INITIAL
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [formStep, setFormStep] = useState<number>(0)

  const isSingleForm = width < BreakpointUnits.TABLET

  const submitForm = async (values: RSVPForm) => {
    if (isSubmitting) return

    setIsSubmitting(true)

    const headers = new Headers()
    headers.append("Content-type", "application/json; charset=UTF-8")

    fetch("https://norrlands.nordburo.com/api/invite.php", {
      method: "POST",
      body: JSON.stringify(values),
      headers,
    })
      .then(response => response.json())
      .then(({ success }: { success: boolean; errors?: string[] }) => {
        if (success) {
          setHeaderState(
            values.attending === RSVPAttendingOptions.YES
              ? RSVPHeaderStates.ATTEND_YES
              : RSVPHeaderStates.ATTEND_NO
          )
        }
      })
      .catch(error => {
        console.log("Error", error)
      })
  }

  const submitHandler = async (values: RSVPForm) => {
    if (isSingleForm) {
      if (values.attending === RSVPAttendingOptions.YES && formStep === 0) {
        setFormStep(1)
        return
      }
    }
    submitForm(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema(isSingleForm, formStep)}
    >
      {({ handleSubmit, values }) => (
        <>
          <RSVPHeaderMolecule
            animate={animate}
            bodyTextInit={bodyText}
            bodyTextNo={submittedBodyTextNo}
            bodyTextYes={submittedBodyTextYes}
            delay={delay}
            headerState={headerState}
            headingInit={heading}
            headingNo={submittedHeadingNo}
            headingYes={submittedHeadingYes}
          />
          <RSVPFormMolecule
            animate={
              headerState === RSVPHeaderStates.INITIAL
                ? animate
                : AnimateStates.ANIMATE_OUT
            }
            delay={animate === AnimateStates.ANIMATE_IN ? delay + 0.3 : delay}
            isSingleForm={isSingleForm}
            isStaying={
              values?.accommodation !== RSVPAccommodationOptions.NOT_STAYING &&
              values?.accommodation !== RSVPAccommodationOptions.FAMILY
            }
            isSubmitting={isSubmitting}
            onBack={() => setFormStep(0)}
            onSubmit={handleSubmit}
            step={formStep}
            attending={values?.attending}
            {...props}
          />
        </>
      )}
    </Formik>
  )
}

export default RSVPFormOrganism
