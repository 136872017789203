const BASE_FONT_SIZE = 16

const Typography = {
  BASE_FONT_SIZE,

  FONT_FAMILY_BODY: "Albra-Sans-Regular",
  FONT_FAMILY_BODY_ITALIC: "Albra-Regular-Italic",

  FONT_FAMILY_HEADING: "Albra-Bold",

  FONT_FAMILY_HEADING1: "Albra-Bold",
  FONT_FAMILY_HEADING2: "Albra-Regular-Italic",
  FONT_FAMILY_HEADING3: "Albra-Bold",
  FONT_FAMILY_HEADING4: "Albra-Sans-Regular",
  FONT_FAMILY_HEADING5: "Albra-Sans-Regular",

  FONT_WEIGHT_REGULAR: 400,
  FONT_WEIGHT_MEDIUM: 500,
  FONT_WEIGHT_BOLD: 500,

  HTML_FONT_SIZE_MOBILE: "clamp(16px, calc(1.5vw + 1.5vh), 20px)",
  HTML_FONT_SIZE_TABLET: "16px",
  HTML_FONT_SIZE_XL_DESKTOP: "clamp(16px, calc(0.675vw + 0.675vh), 32px)",

  // Mobile
  FONT_SIZE_MOBILE_HEADING1: 64,
  FONT_SIZE_MOBILE_HEADING2: 36,
  FONT_SIZE_MOBILE_HEADING3: 40,
  FONT_SIZE_MOBILE_HEADING4: 24,
  FONT_SIZE_MOBILE_HEADING5: 20,
  FONT_SIZE_MOBILE_INTRO: 22,
  FONT_SIZE_MOBILE_LABEL: 18,
  FONT_SIZE_MOBILE_PARAGRAPH: 16,
  FONT_SIZE_MOBILE_FOOTER: 13,
  FONT_SIZE_MOBILE_MENU_ITEM: 48,

  LETTER_SPACING_MOBILE_HEADING1: -1.5,
  LETTER_SPACING_MOBILE_HEADING2: -1.58,
  LETTER_SPACING_MOBILE_HEADING3: -0.5,
  LETTER_SPACING_MOBILE_HEADING4: -1.2,
  LETTER_SPACING_MOBILE_HEADING5: -1,
  LETTER_SPACING_MOBILE_INTRO: -1.28,
  LETTER_SPACING_MOBILE_LABEL: -0.6,
  LETTER_SPACING_MOBILE_PARAGRAPH: -0.37,
  LETTER_SPACING_MOBILE_FOOTER: -0.76,
  LETTER_SPACING_MOBILE_MENU_ITEM: -4.2,

  LINE_HEIGHT_MOBILE_HEADING1: 60,
  LINE_HEIGHT_MOBILE_HEADING2: 36,
  LINE_HEIGHT_MOBILE_HEADING3: 40,
  LINE_HEIGHT_MOBILE_HEADING4: 24,
  LINE_HEIGHT_MOBILE_HEADING5: 20,
  LINE_HEIGHT_MOBILE_INTRO: 22,
  LINE_HEIGHT_MOBILE_LABEL: 20,
  LINE_HEIGHT_MOBILE_PARAGRAPH: 18,
  LINE_HEIGHT_MOBILE_FOOTER: 16,
  LINE_HEIGHT_MOBILE_MENU_ITEM: 48,

  // Tablet
  FONT_SIZE_TABLET_HEADING1: 94,
  FONT_SIZE_TABLET_HEADING2: 46,
  FONT_SIZE_TABLET_HEADING3: 52,
  FONT_SIZE_TABLET_HEADING4: 30,
  FONT_SIZE_TABLET_HEADING5: 26,
  FONT_SIZE_TABLET_INTRO: 24,
  FONT_SIZE_TABLET_LABEL: 20,
  FONT_SIZE_TABLET_PARAGRAPH: 18,
  FONT_SIZE_TABLET_FOOTER: 16,
  FONT_SIZE_TABLET_MENU_ITEM: 64,

  LETTER_SPACING_TABLET_HEADING1: -1.25,
  LETTER_SPACING_TABLET_HEADING2: -2.015,
  LETTER_SPACING_TABLET_HEADING3: -0.65,
  LETTER_SPACING_TABLET_HEADING4: -1.6,
  LETTER_SPACING_TABLET_HEADING5: -1.3,
  LETTER_SPACING_TABLET_INTRO: 0,
  LETTER_SPACING_TABLET_LABEL: -0.8,
  LETTER_SPACING_TABLET_PARAGRAPH: -0.42,
  LETTER_SPACING_TABLET_FOOTER: -0.8,
  LETTER_SPACING_TABLET_MENU_ITEM: -5.6,

  LINE_HEIGHT_TABLET_HEADING1: 94,
  LINE_HEIGHT_TABLET_HEADING2: 46,
  LINE_HEIGHT_TABLET_HEADING3: 52,
  LINE_HEIGHT_TABLET_HEADING4: 30,
  LINE_HEIGHT_TABLET_HEADING5: 26,
  LINE_HEIGHT_TABLET_INTRO: 24,
  LINE_HEIGHT_TABLET_LABEL: 20,
  LINE_HEIGHT_TABLET_PARAGRAPH: 24,
  LINE_HEIGHT_TABLET_FOOTER: 20,
  LINE_HEIGHT_TABLET_MENU_ITEM: 64,

  // XL Desktop
  FONT_SIZE_XL_DESKTOP_HEADING1: 120,
  FONT_SIZE_XL_DESKTOP_HEADING2: 56,
  FONT_SIZE_XL_DESKTOP_HEADING3: 64,
  FONT_SIZE_XL_DESKTOP_HEADING4: 40,
  FONT_SIZE_XL_DESKTOP_HEADING5: 32,
  FONT_SIZE_XL_DESKTOP_INTRO: 24,
  FONT_SIZE_XL_DESKTOP_LABEL: 20,
  FONT_SIZE_XL_DESKTOP_PARAGRAPH: 18,
  FONT_SIZE_XL_DESKTOP_FOOTER: 16,
  FONT_SIZE_XL_DESKTOP_MENU_ITEM: 80,

  LETTER_SPACING_XL_DESKTOP_HEADING1: -1,
  LETTER_SPACING_XL_DESKTOP_HEADING2: -2.45,
  LETTER_SPACING_XL_DESKTOP_HEADING3: -0.8,
  LETTER_SPACING_XL_DESKTOP_HEADING4: -2,
  LETTER_SPACING_XL_DESKTOP_HEADING5: -1.6,
  LETTER_SPACING_XL_DESKTOP_INTRO: 0,
  LETTER_SPACING_XL_DESKTOP_LABEL: -0.8,
  LETTER_SPACING_XL_DESKTOP_PARAGRAPH: -0.42,
  LETTER_SPACING_XL_DESKTOP_FOOTER: -0.8,
  LETTER_SPACING_XL_DESKTOP_MENU_ITEM: -7,

  LINE_HEIGHT_XL_DESKTOP_HEADING1: 120,
  LINE_HEIGHT_XL_DESKTOP_HEADING2: 56,
  LINE_HEIGHT_XL_DESKTOP_HEADING3: 64,
  LINE_HEIGHT_XL_DESKTOP_HEADING4: 40,
  LINE_HEIGHT_XL_DESKTOP_HEADING5: 32,
  LINE_HEIGHT_XL_DESKTOP_INTRO: 24,
  LINE_HEIGHT_XL_DESKTOP_LABEL: 20,
  LINE_HEIGHT_XL_DESKTOP_PARAGRAPH: 24,
  LINE_HEIGHT_XL_DESKTOP_FOOTER: 20,
  LINE_HEIGHT_XL_DESKTOP_MENU_ITEM: 80,
}

export default Typography
