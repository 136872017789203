import React, { FC } from "react"
import { Variants, motion } from "framer-motion"
import styled from "styled-components"

import { AnimateStates } from "@common/constants"
import { useViewportObserver } from "@common/hooks"

import { Colors, Easings } from "@constants"
import { MenuStates } from "../organisms/MenuOrganism"

const variants = {
  [AnimateStates.INITIAL]: ({ delay = 0 }): unknown => ({
    pathLength: 0,
    pathOffset: 0,
    transition: {
      delay,
      duration: 0,
    },
  }),
  [AnimateStates.ANIMATE_IN]: ({ delay = 0 }): unknown => ({
    pathLength: 1,
    pathOffset: [0, 0],
    transition: {
      delay,
      duration: 1,
      ease: Easings.EaseInOutSoft,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay = 0 }) => ({
    pathLength: 1,
    pathOffset: 1,
    transition: {
      delay: delay + 0.33,
      duration: 0.66,
      ease: Easings.EaseInOutSoft,
    },
  }),
} as Variants

export interface MenuBorderMoleculeProps {
  animate: MenuStates
  className?: string
  delay?: number
}

const MenuBorderMolecule: FC<MenuBorderMoleculeProps> = ({
  animate,
  className,
  delay,
}) => {
  const { width, height } = useViewportObserver()

  return (
    <styles.Border
      className={className}
      height={height}
      width={width}
      viewBox={`0 0 ${width} ${height}`}
    >
      <styles.Path
        animate={animate}
        custom={{
          delay,
        }}
        d={`M16,16 ${width - 16},16 ${width - 16},${height - 16}`}
        fill="none"
        shapeRendering="crispEdges"
        stroke={Colors.BLACK}
        strokeWidth="2"
        initial={{
          pathLength: 0,
        }}
        variants={variants}
      />
      <styles.Path
        animate={animate}
        custom={{
          delay,
        }}
        d={`M16,16 16,${height - 16} ${width - 16},${height - 16} `}
        fill="none"
        shapeRendering="crispEdges"
        stroke={Colors.BLACK}
        strokeWidth="2"
        initial={{
          pathLength: 0,
        }}
        variants={variants}
      />
    </styles.Border>
  )
}

const styles = {
  Border: styled.svg`
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
  `,
  Path: styled(motion.path)``,
}

export default MenuBorderMolecule
