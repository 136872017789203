import React, { FC, useRef } from "react"
import { motion, Variants } from "framer-motion"
import styled from "styled-components"

import { AnimatedSplitTextAtom, ButtonAtom } from "@common/components/atoms"
import { ButtonAtomProps } from "@common/components/atoms/ButtonAtom"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import { BorderMolecule } from "@components/molecules"

import { Colors, Typography } from "@constants"

export enum ButtonTypes {
  BUTTON = "button",
  SUBMIT = "submit",
}

export enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface ButtonMoleculeProps {
  className?: string
  delay: number
  disabled?: boolean
  text: string
  type?: "button" | "submit"
  variant?: ButtonVariants
}

const BackgroundColors = {
  [ButtonVariants.PRIMARY]: {
    [Colors.BLACK]: Colors.BLACK,
    [Colors.WHITE]: Colors.WHITE,
  },
  [ButtonVariants.SECONDARY]: {
    [Colors.BLACK]: Colors.WHITE,
    [Colors.WHITE]: Colors.BLACK,
  },
}

const TextColors = {
  [ButtonVariants.PRIMARY]: {
    [Colors.BLACK]: Colors.WHITE,
    [Colors.WHITE]: Colors.BLACK,
  },
  [ButtonVariants.SECONDARY]: {
    [Colors.BLACK]: Colors.BLACK,
    [Colors.WHITE]: Colors.WHITE,
  },
}

const backgroundVariants = {
  [AnimateStates.INITIAL]: {
    opacity: 0,
  },
  [AnimateStates.ANIMATE_IN]: ({ delay }) => ({
    opacity: 1,
    transition: {
      delay: delay + 0.15,
      duration: 0.3,
    },
  }),
  [AnimateStates.ANIMATE_OUT]: ({ delay }) => ({
    opacity: 0,
    transition: {
      delay,
      duration: 0.3,
    },
  }),
} as Variants

const ButtonMolecule: FC<ButtonAtomProps & AnimatedStateComponent> = ({
  animate,
  className,
  color = Colors.WHITE,
  delay = 0,
  disabled = false,
  onClick,
  onMouseOut,
  onMouseOver,
  text,
  type = ButtonTypes.BUTTON,
  variant = ButtonVariants.PRIMARY,
}) => {
  const ref = useRef<ButtonAtom>(null)

  return (
    <styles.Container className={className} ref={ref}>
      <styles.Button
        $color={color}
        disabled={disabled}
        onClick={onClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        type={type}
      >
        <styles.Background
          $color={BackgroundColors[variant][color] ?? "transparent"}
          initial={AnimateStates.INITIAL}
          animate={animate}
          custom={{
            delay,
          }}
          variants={backgroundVariants}
        />
        <styles.Border
          animate={animate}
          borderColor={color}
          borderWidth={1}
          delay={delay}
          ref={ref}
        />
        <styles.Text
          $color={TextColors[variant][color] ?? color}
          animate={animate}
          delay={delay + (animate === AnimateStates.ANIMATE_IN ? 0.2 : 0)}
          duration={0.3}
        >
          {text}
        </styles.Text>
      </styles.Button>
    </styles.Container>
  )
}

const styles = {
  Container: styled.div`
    display: inline-flex;
  `,
  Button: styled(ButtonAtom)<{ $color: Colors }>`
    color: ${({ $color }) => $color};
    display: flex;
    font-family: ${Typography.FONT_FAMILY_BODY};
    font-size: ${rem(20)};
    line-height: 1;
    padding: ${rem(16)} ${rem(20)};
    position: relative;

    &[disabled] {
      opacity: 0.5;
    }

    ${MediaQueries.DESKTOP} {
      padding: ${rem(17)} ${rem(56)};
    }
  `,
  Background: styled(motion.div)<{ $color: Colors }>`
    background-color: ${({ $color }) => $color};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,
  Border: styled(BorderMolecule)`
    pointer-events: none;
  `,
  Text: styled(AnimatedSplitTextAtom)<{ $color: Colors }>`
    color: ${({ $color }) => $color};
    position: relative;
    top: ${rem(1.5)};
  `,
}

export default ButtonMolecule
