import { navigate as gatsbyNavigate } from "gatsby"

const scrollLock = () => {
  document.body.style.overflow = "hidden"
}

const scrollUnlock = () => {
  document.body.style.overflow = "auto"
}

const navigate = (url: string, jumpToTop: boolean = false) => {
  scrollLock()

  setTimeout(() => {
    gatsbyNavigate(url)
  }, 100)
}

export { navigate, scrollLock, scrollUnlock }
