import { useState } from "react"

import usePrevious from "./usePrevious"

export interface StepperProps {
  step: number | null
  goBack: () => void
  goForward: () => void
  goTo: (value: number) => void
  previousStep: number | null
}

const useStepper = (initialStep: number, totalSteps: number): StepperProps => {
  const [step, setStep] = useState<number>(initialStep)

  const previousStep = usePrevious(step) as number

  const goBack = () =>
    setStep(previous => (previous - 1 >= 0 ? previous - 1 : previous))

  const goForward = () =>
    setStep(previous => (previous + 1 < totalSteps ? previous + 1 : previous))

  const goTo = (value: number) =>
    setStep(previous =>
      value !== previous && value >= 0 && value < totalSteps ? value : previous
    )

  return {
    goBack,
    goForward,
    goTo,
    previousStep,
    step,
  }
}

export default useStepper
