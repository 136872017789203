import * as THREE from "three"

import { isClient } from "@common/hooks/useViewportObserver"
import { on } from "@common/utils"

import Background, { WebGLBackground } from "@gfx/Background"
import { getTextures } from "@gfx/Loader"
import Particles, { WebGLParticles } from "@gfx/Particles"

import { Colors } from "@constants"

import { getSize } from "@utils/MediaQueryUtils"

export interface WebGLProps {
  init: (view: HTMLCanvasElement) => void
}

export enum WebGLEvents {
  READY = "ready",
  UPDATE = "update",
  START_JITTER = "startJitter",
  STOP_JITTER = "stopJitter",
}

const getTextureByColor = (
  width: number,
  height: number,
  backgroundColor: string
): THREE.Texture | null => {
  const canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height

  const context = canvas.getContext("2d")
  if (context) {
    context.fillStyle = backgroundColor
    context.fillRect(0, 0, canvas.width, canvas.height)

    const texture = new THREE.CanvasTexture(canvas)
    return texture as THREE.Texture
  }
  return null
}

const WebGL = (): WebGLProps => {
  let _canvas: HTMLElement,
    // THREE objects
    _camera: THREE.PerspectiveCamera,
    _renderer: THREE.WebGLRenderer,
    _scene: THREE.Scene,
    _textures: { [key: string]: THREE.Texture },
    _background: WebGLBackground,
    _particles: WebGLParticles,
    _backgroundTextureId: string,
    _clock: THREE.Clock,
    // THREE values
    _height: number,
    _isAndroid: boolean = false,
    _isPaused: boolean = false,
    _isPlaying: boolean = false,
    _time: number,
    _width: number

  // Private - methods
  const initialize = () => {
    _height = isClient ? window.innerHeight : 1
    _width = isClient ? window.innerWidth : 1

    _isAndroid = isClient ? /android/i.test(navigator.userAgent) : false

    _renderer = new THREE.WebGLRenderer({
      canvas: _canvas,
    })
    _renderer.setPixelRatio(window.devicePixelRatio)
    _renderer.setSize(_width, _height)
    _renderer.setClearColor(0xff0000, 1)

    // Particle camera
    _camera = new THREE.PerspectiveCamera(50, _width / _height, 1, 10000)
    _camera.position.set(0, 0, 300)

    _scene = new THREE.Scene()

    _clock = new THREE.Clock()

    const size = getSize()

    // Set mobile and desktop textures
    if (size === "mobile") {
      _textures["mobile_black"] = getTextureByColor(375, 667, Colors.BLACK)
      _textures["mobile_white"] = getTextureByColor(375, 667, Colors.WHITE)
    } else {
      _textures["desktop_black"] = getTextureByColor(1440, 900, Colors.BLACK)
      _textures["desktop_white"] = getTextureByColor(1440, 900, Colors.WHITE)
    }

    _backgroundTextureId = `${size}_black`

    // Background plane
    _background = Background()
    const background = _background.init(_textures[_backgroundTextureId])
    _scene.add(background)

    // Enable particle plane only on larger screens that aren't Android
    if (size === "desktop" && _isAndroid === false) {
      _particles = Particles()
      const particles = _particles.init()
      if (particles) {
        _scene.add(particles)
      }
    }
  }

  const render = () => {
    if (_isPaused) return

    _time += 0.05

    _background.render(_time)

    if (_particles) {
      _particles.render(_clock.getDelta())
    }

    _renderer.render(_scene, _camera)

    requestAnimationFrame(render)
  }

  const updateHandler = (
    backgroundTextureId: string,
    particleTextureId: string,
    duration: number = 1.5
  ) => {
    if (
      _isPlaying &&
      _backgroundTextureId === backgroundTextureId &&
      _textures[backgroundTextureId] === undefined
    )
      return
    _isPlaying = true
    _backgroundTextureId = backgroundTextureId

    _background.animate(
      _textures[_backgroundTextureId],
      () => {
        _isPlaying = false
      },
      duration
    )

    if (_particles) {
      if (_textures[particleTextureId] === undefined) {
        _particles.hide()
      } else {
        _particles.animate(_textures[particleTextureId], duration)
      }

      const fovHeight =
        2 * Math.tan((_camera.fov * Math.PI) / 180 / 2) * _camera.position.z
      _particles.resize(fovHeight)
    }
  }

  const startJitterHandler = (event: string) => {
    // console.log("startJitterHandler", event)
  }

  const stopJitterHandler = (event: string) => {
    // console.log("stopJitterHandler", event)
  }

  // Private - event handlers
  const resizeHandler = () => {
    _width = window.innerWidth
    _height = window.innerHeight

    _renderer.setSize(_width, _height)

    _camera.aspect = _width / _height
    _camera.updateProjectionMatrix()

    const cameraZ = _camera.position.z

    _background.resize(_width, _height, _camera.aspect * cameraZ, cameraZ)

    if (_particles) {
      const fovHeight =
        2 * Math.tan((_camera.fov * Math.PI) / 180 / 2) * _camera.position.z
      _particles.resize(fovHeight)
    }
  }

  // Private: Event handlers
  on(WebGLEvents.UPDATE, updateHandler)
  // on(WebGLEvents.START_JITTER, startJitterHandler)
  // on(WebGLEvents.STOP_JITTER, stopJitterHandler)

  // Public
  const init = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      _canvas = canvas

      // Disable timeout
      setTimeout(() => {
        _textures = getTextures()

        initialize()
        resizeHandler()
        render()

        if (isClient) {
          window.addEventListener("resize", resizeHandler)
        }

        _renderer.render(_scene, _camera)
      }, 500)
    } else {
      // TODO: Add error
    }
  }

  return {
    init: init,
  }
}

const instance = WebGL()

export default instance
