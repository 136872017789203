export enum BackgroundColors {
  BLACK = "black",
  WHITE = "white",
}

export interface TextureInfo {
  publicUrl: string
  slug: string
}

export interface BackgroundSlide {
  desktop: {
    background: TextureInfo
    particle?: TextureInfo
  }
  mobile: {
    background: TextureInfo
    particle?: TextureInfo
  }
}
