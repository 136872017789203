import BezierEasing from "bezier-easing"

const Easings = {
  EaseIn: BezierEasing(0.65, 0.0, 0.85, 0.0),
  EaseOut: BezierEasing(0.15, 1.0, 0.35, 1.0),
  EaseInOutStrong: BezierEasing(0.65, 0.0, 0.0, 1.0),
  EaseInOutSoft: BezierEasing(0.35, 0.0, 0.0, 1.0),
  Linear: BezierEasing(0, 0, 1, 1),
  CssEaseIn: "cubic-bezier(0.65, 0.0, 0.85, 0.0)",
  CssEaseOut: "cubic-bezier(0.15, 1.0, 0.35, 1.0)",
  CssEaseInOutStrong: "cubic-bezier(0.65, 0, 0, 1)",
  CssEaseInOutSoft: "cubic-bezier(0.35, 0.0, 0.0, 1.0)",
  CssLinear: "cubic-beizer(0, 0, 1, 1)",
}
export default Easings
