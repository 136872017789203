import React, { FC } from "react"
import styled from "styled-components"

import { AnimatedHeadingMolecule } from "@common/components/molecules"
import { AnimateStates, MediaQueries } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import {
  AgendaSlideMolecule,
  SectionMolecule,
  SliderMolecule,
} from "@components/molecules"
import {
  SectionHorizontalAlignments,
  SectionVerticalAlignments,
  SectionSizes,
} from "@components/molecules/SectionMolecule"

import { Colors } from "@constants"

import { HomeSection } from "@types/HomeSection"

export interface AgendaItem {
  heading: string
  bodyText: string
}

export interface HomeAgendaOrganismProps {
  heading: string
  items: AgendaItem[]
}

const HomeAgendaOrganism: FC<
  HomeAgendaOrganismProps & HomeSection & AnimatedStateComponent
> = ({ animate, direction, heading, items }) => (
  <SectionMolecule
    animate={animate}
    desktopAlignHorizontal={SectionHorizontalAlignments.LEFT}
    desktopAlignVertical={SectionVerticalAlignments.BOTTOM}
    mobileAlignVertical={SectionVerticalAlignments.TOP}
    size={SectionSizes.HALF_WIDTH}
  >
    <styles.Heading
      animate={animate}
      delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
      direction={direction}
      size="h3"
    >
      {heading}
    </styles.Heading>
    <SliderMolecule
      animate={animate}
      delay={animate === AnimateStates.ANIMATE_IN ? 0.5 : 0}
      direction={direction}
      items={items}
      slideComponent={AgendaSlideMolecule}
    />
  </SectionMolecule>
)

const styles = {
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: ${rem(20)};

    ${MediaQueries.DESKTOP} {
      margin-bottom: ${rem(40)};
    }
  `,
}

export default HomeAgendaOrganism
