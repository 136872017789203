import * as Yup from "yup"

export enum RSVPAttendingOptions {
  EMPTY = "-",
  YES = "YES",
  NO = "NO",
}

export enum RSVPAccommodationOptions {
  EMPTY = "-",
  TENT = "TENT",
  CAMPER_VAN = "CAMPERVAN",
  HOTEL = "HOTEL",
  FAMILY = "FAMILY",
  NOT_STAYING = "NOT_STAYING",
}

export enum RSVPTransportationOptions {
  EMPTY = "-",
  CAR = "CAR",
  TRAIN = "TRAIN",
}

export enum RSVPDurationOptions {
  EMPTY = "-",
  THURSDAY_TO_SUNDAY = "THURSDAY_TO_SUNDAY",
  THURSDAY_TO_MONDAY = "THURSDAY_TO_MONDAY",
  THURSDAY_AND_LONGER = "THURSDAY_AND_LONGER",
  FRIDAY_TO_SUNDAY = "FRIDAY_TO_SUNDAY",
  FRIDAY_TO_MONDAY = "FRIDAY_TO_MONDAY",
  FRIDAY_AND_LONGER = "FRIDAY_AND_LONGER",
}

export interface RSVPForm {
  name: string
  email: string
  attending: RSVPAttendingOptions
  accommodation: RSVPAccommodationOptions
  transportation: RSVPTransportationOptions
  duration: RSVPDurationOptions
}

export const initialValues = {
  name: "",
  email: "",
  attending: "",
  accommodation: "",
  transportation: "",
  duration: "",
}

export const ERROR_MESSAGE = "error"

const fieldset1Schema = {
  name: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().email(ERROR_MESSAGE).required(ERROR_MESSAGE),
  attending: Yup.string()
    .oneOf([RSVPAttendingOptions.YES, RSVPAttendingOptions.NO])
    .required(ERROR_MESSAGE),
}

const fieldset2Schema = {
  accommodation: Yup.string().when("attending", {
    is: (attending: string) => attending === RSVPAttendingOptions.YES,
    then: schema =>
      schema.notOneOf([RSVPAccommodationOptions.EMPTY]).required(ERROR_MESSAGE),
  }),
  transportation: Yup.string().when("attending", {
    is: (attending: string) => attending === RSVPAttendingOptions.YES,
    then: schema =>
      schema
        .notOneOf([RSVPTransportationOptions.EMPTY])
        .required(ERROR_MESSAGE),
  }),
  duration: Yup.string().when(["attending", "accommodation"], {
    is: (attending: string, accommodation: string) =>
      attending === RSVPAttendingOptions.YES &&
      accommodation !== RSVPAccommodationOptions.NOT_STAYING &&
      accommodation !== RSVPAccommodationOptions.FAMILY,
    then: schema =>
      schema.notOneOf([RSVPDurationOptions.EMPTY]).required(ERROR_MESSAGE),
  }),
}

export const validationSchema = (isMobile?: boolean, step?: number) =>
  isMobile && step === 0
    ? Yup.object().shape({ ...fieldset1Schema })
    : Yup.object().shape({
        ...fieldset1Schema,
        ...fieldset2Schema,
      })
