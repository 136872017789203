import { MediaQueries } from "@common/constants"

import Typography from "@constants/Typography"

const Fonts = `
  @font-face {
    font-family: "${Typography.FONT_FAMILY_BODY}";
    src: url('/fonts/Albra-Sans-Regular.woff2') format('woff2'),
    url('/fonts/Albra-Sans-Regular.woff') format('woff');
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "${Typography.FONT_FAMILY_BODY}";
    src: url('/fonts/Albra-Regular-Italic.woff2') format('woff2'),
    url('/fonts/Albra-Regular-Italic.woff') format('woff');
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "${Typography.FONT_FAMILY_BODY_ITALIC}";
    src: url('/fonts/Albra-Regular-Italic.woff2') format('woff2'),
    url('/fonts/Albra-Regular-Italic.woff') format('woff');
    font-weight: ${Typography.FONT_WEIGHT_REGULAR};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "${Typography.FONT_FAMILY_HEADING}";
    src: url('/fonts/Albra-Bold.woff2') format('woff2'),
    url('/fonts/Albra-Bold.woff') format('woff');
    font-weight: ${Typography.FONT_WEIGHT_BOLD};
    font-style: normal;
    font-display: swap;
  }

  html {
    font-family: "${Typography.FONT_FAMILY_BODY}";
    font-size: ${Typography.HTML_FONT_SIZE_MOBILE};

    ${MediaQueries.TABLET} {
      font-size: ${Typography.HTML_FONT_SIZE_TABLET};
    }

    ${MediaQueries.XL_DESKTOP} {
      font-size: ${Typography.HTML_FONT_SIZE_XL_DESKTOP};
    }
  }  
`

export default Fonts
