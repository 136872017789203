import { useEffect } from "react"

import { throttle } from "../utils"

const MIN_DELTA = 2
const THROTTLE_TIMEOUT = 250

export enum MouseWheelDirection {
  DOWN = "down",
  UP = "up",
}

/**
 * Hook with callback to listen for mouse wheel and return direction (up or down)
 * Throttles scroll for MacBooks with inertia scroll
 *
 * @param callback
 */
const useMouseWheel = (callback: (direction: MouseWheelDirection) => void) => {
  const scrollHandler = throttle((e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { deltaY } = e
    const direction =
      deltaY > MIN_DELTA
        ? MouseWheelDirection.DOWN
        : deltaY < -MIN_DELTA
        ? MouseWheelDirection.UP
        : null

    if (direction && callback) {
      callback(direction)
    }
  }, THROTTLE_TIMEOUT)

  useEffect(() => {
    window.addEventListener("wheel", scrollHandler, { passive: false })

    return (): void => {
      window.removeEventListener("wheel", scrollHandler)
    }
  }, [])
}

export default useMouseWheel
