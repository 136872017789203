const handlers: { [key: string]: (() => void)[] } = {}

const on = (key: string, callback: () => void): boolean => {
  if (!handlers.hasOwnProperty(key)) {
    handlers[key] = []
  }

  handlers[key].push(callback)

  return true
}

const off = (key: string, callback: () => void): boolean => {
  if (!handlers.hasOwnProperty(key)) {
    return false
  }

  handlers[key].forEach((cb, index) => {
    if (cb === callback) {
      handlers[key].splice(index, 1)

      return true
    }
  })

  return false
}

const publish = (key: string, ...args: any[]): boolean => {
  if (!handlers.hasOwnProperty(key)) {
    return false
  }

  handlers[key].forEach(cb => cb.apply(undefined, args))

  return true
}

export { on, off, publish }
