import React, { FC, ReactNode } from "react"
import styled from "styled-components"

import { Spacings } from "@constants"

export interface ContainerAtomProps {
  children?: ReactNode
  className?: string
}

const ContainerAtom: FC<ContainerAtomProps> = ({ children, className }) => (
  <styles.Container className={className}>{children}</styles.Container>
)

const styles = {
  Container: styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    padding: ${Spacings.SECTION_Y_MOBILE} ${Spacings.BORDER_X_MOBILE};
  `,
}

export default ContainerAtom
