import React, { FC, useState } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import { AnimatedSplitTextAtom, LinkAtom } from "@common/components/atoms"
import { rem } from "@common/utils"
import { AnimateStates } from "@common/constants"

import { Colors, Easings } from "@constants"

export interface SlideIndicatorItem {
  id: string
  label: string
}

export interface SlideIndicatorLinkMoleculeProps {
  color?: Colors
  delay: number
  isActive: boolean
}

const SlideIndicatorLinkMolecule: FC<
  SlideIndicatorLinkMoleculeProps & SlideIndicatorItem
> = ({ color = Colors.WHITE, delay = 0, id, isActive, label }) => {
  const [animate, setAnimate] = useState<AnimateStates>(AnimateStates.INITIAL)

  return (
    <styles.Link
      href={`#${id}`}
      onBlur={() => setAnimate(AnimateStates.ANIMATE_OUT)}
      onMouseOver={() => setAnimate(AnimateStates.ANIMATE_IN)}
      onMouseOut={() => setAnimate(AnimateStates.ANIMATE_OUT)}
      onFocus={() => setAnimate(AnimateStates.ANIMATE_IN)}
    >
      <styles.Text color={color}>
        <AnimatedSplitTextAtom
          initial={AnimateStates.INITIAL}
          animate={animate}
        >
          {label}
        </AnimatedSplitTextAtom>
      </styles.Text>
      <styles.Icon
        initial={{ scale: 0, backgroundColor: color }}
        animate={{
          backgroundColor: color,
          scale: isActive ? 1 : 0.5,
        }}
        transition={{
          duration: 1,
          delay,
          ease: Easings.EaseInOutSoft,
        }}
      />
    </styles.Link>
  )
}

const styles = {
  Link: styled(LinkAtom)`
    align-items: center;
    display: flex;
    height: ${rem(40)};
    justify-content: center;
    position: relative;
    width: ${rem(40)};
    z-index: 1;
    pointer-events: auto;
  `,
  Text: styled.span`
    color: ${Colors.WHITE};
    display: flex;
    line-height: 1;
    position: absolute;
    pointer-events: none;
    top: calc(50% + ${rem(1)});
    right: calc(100% - ${rem(8)});
    transform: translateY(-50%);
  `,
  Icon: styled(motion.span)`
    border-radius: 100%;
    display: block;
    height: ${rem(12)};
    width: ${rem(12)};
  `,
}

export default SlideIndicatorLinkMolecule
