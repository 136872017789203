import React, { createContext, FC, ReactNode, useState } from "react"

export interface ApplicationContextProps {
  closeMenu: (url?: string) => void
  disableScroll: () => void
  enableScroll: () => void
  hasLoaded: boolean
  openMenu: () => void
  scrollEnabled: boolean
  setHasLoaded: (value: boolean) => void
}

const initialState: ApplicationContextProps = {
  closeMenu: (url?: string) => {},
  disableScroll: () => {},
  enableScroll: () => {},
  hasLoaded: false,
  openMenu: () => {},
  scrollEnabled: false,
  setHasLoaded: (value: boolean) => {},
}

const ApplicationContext = createContext<ApplicationContextProps>(initialState)

export interface ApplicationProviderProps {
  children: ReactNode
  closeMenu: (url?: string) => void
  disableScroll: () => void
  enableScroll: () => void
  hasLoaded: boolean
  openMenu: () => void
  scrollEnabled: boolean
}

const ApplicationProvider: FC<ApplicationProviderProps> = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(false)

  // Public methods
  const openMenu = () => {
    //
  }

  const closeMenu = (url?: string) => {
    //
  }

  const enableScroll = () => setScrollEnabled(true)

  const disableScroll = () => setScrollEnabled(false)

  return (
    <>
      <ApplicationContext.Provider
        value={{
          closeMenu,
          enableScroll,
          disableScroll,
          hasLoaded,
          openMenu,
          scrollEnabled,
          setHasLoaded,
        }}
      >
        {children}
      </ApplicationContext.Provider>
    </>
  )
}

export { ApplicationProvider }

export default ApplicationContext
