import useKey, { KeyArrows } from "./useKey"
import useMouseWheel, { MouseWheelDirection } from "./useMouseWheel"
import useSwipe, { SwipeDirection } from "./useSwipe"

import { matchToLower } from "../utils"

export enum ScrollDirection {
  STOP = "stop",
  UP = "up",
  DOWN = "down",
}

const useScroll = (callback: (direction: ScrollDirection) => void) => {
  useKey((key: string) => {
    if (matchToLower(KeyArrows.UP, key)) callback(ScrollDirection.UP)
    else if (matchToLower(KeyArrows.DOWN, key)) callback(ScrollDirection.DOWN)
  })

  useSwipe((swipeDirection: SwipeDirection) => {
    if (swipeDirection === SwipeDirection.UP) callback(ScrollDirection.UP)
    else if (swipeDirection === SwipeDirection.DOWN)
      callback(ScrollDirection.DOWN)
  })

  useMouseWheel((mouseWheelDirection: MouseWheelDirection) => {
    if (mouseWheelDirection === MouseWheelDirection.UP)
      callback(ScrollDirection.UP)
    else if (mouseWheelDirection === MouseWheelDirection.DOWN)
      callback(ScrollDirection.DOWN)
  })
}

export default useScroll
