import React, { FC, ReactNode } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

export interface MotionWrapperOrganismProps {
  children: ReactNode
}

const MotionWrapperOrganism: FC<MotionWrapperOrganismProps> = ({
  children,
}) => (
  <styles.MotionWrapper
    initial={{
      zIndex: 1,
      visibility: "hidden",
    }}
    animate={"enter"}
    exit="exit"
    variants={{
      enter: {
        zIndex: 1,
        visibility: "visible",
      },
      exit: {
        zIndex: 2,
      },
    }}
  >
    {children}
  </styles.MotionWrapper>
)

const styles = {
  MotionWrapper: styled(motion.div)`
    pointer-events: none;
    position: relative;
    z-index: 1;
  `,
}

export default MotionWrapperOrganism
