import React, { FC } from "react"
import styled from "styled-components"

import { AnimatedHeadingMolecule } from "@common/components/molecules"
import { AnimateStates } from "@common/constants"
import { AnimatedStateComponent } from "@common/types"
import { rem } from "@common/utils"

import {
  FAQSlideMolecule,
  SectionMolecule,
  SliderMolecule,
} from "@components/molecules"
import {
  SectionHorizontalAlignments,
  SectionSizes,
  SectionVerticalAlignments,
} from "@components/molecules/SectionMolecule"

import { Colors } from "@constants"

import { HomeSection } from "@types/HomeSection"

import { getSize } from "@utils/MediaQueryUtils"

export interface FAQItem {
  question: string
  answer: string
}

export interface HomeFAQOrganismProps {
  headingDesktop: string
  headingMobile: string
  items: FAQItem[]
}

const HomeFAQOrganism: FC<
  HomeFAQOrganismProps & HomeSection & AnimatedStateComponent
> = ({ animate, direction, headingDesktop, headingMobile, items }) => {
  return (
    <SectionMolecule
      animate={animate}
      desktopAlignHorizontal={SectionHorizontalAlignments.RIGHT}
      desktopAlignVertical={SectionVerticalAlignments.BOTTOM}
      size={SectionSizes.FULL_WIDTH}
    >
      <styles.Heading
        animate={animate}
        delay={animate === AnimateStates.ANIMATE_IN ? 0.4 : 0}
        direction={direction}
        size="h3"
      >
        {getSize() === "mobile" ? headingMobile : headingDesktop}
      </styles.Heading>
      <SliderMolecule
        animate={animate}
        doubleOnDesktop
        delay={animate === AnimateStates.ANIMATE_IN ? 0.5 : 0}
        direction={direction}
        items={items}
        slideComponent={FAQSlideMolecule}
      />
    </SectionMolecule>
  )
}

const styles = {
  Heading: styled(AnimatedHeadingMolecule)`
    color: ${Colors.WHITE};
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: ${rem(20)};
  `,
  Body: styled.div`
    color: ${Colors.WHITE};
  `,
}

export default HomeFAQOrganism
