import React, { forwardRef, ReactNode } from "react"
import styled from "styled-components"

export interface FormFieldAtomProps {
  children: ReactNode
  className?: string
}

const FormFieldAtom = forwardRef<HTMLDivElement, FormFieldAtomProps>(
  ({ children, className }, ref) => (
    <styles.Container className={className} ref={ref}>
      {children}
    </styles.Container>
  )
)

const styles = {
  Container: styled.div`
    position: relative;
    display: flex;
  `,
}

export default FormFieldAtom
